import React, { useCallback, useEffect, } from "react"
import { ConnectedProps, connect, useSelector, } from "react-redux"
import { RouteComponentProps, } from "react-router-dom"
import style from "./style.module.scss"
import backgroundImage from "images/session_background.jpg"
import {
  selectState,
  initialize,
  requestContentLoad,
  notifyStart,
  notifyEnd,
} from "./redux"
import history from "utils/history"
import Button from "@material-ui/core/Button"
import AccountIcon from "@material-ui/icons/AccountCircleOutlined"
import CircularProgress from "@material-ui/core/CircularProgress"

const mapDispatch = {
  initialize,
  requestContentLoad,
  notifyStart,
  notifyEnd,
}

const connector = connect(null, mapDispatch)
type Props = ConnectedProps<typeof connector> & RouteComponentProps<{id: string}>

/**
 * The page to join sessions. Display session info and shows navigations to login if user is not logged in.
 */
const SessionPage = ({
  match,
  requestContentLoad,
  initialize,
  notifyStart,
  notifyEnd,
}: Props) => {
  const { id: displayId, } = match.params
  const state = useSelector(selectState)
  const {
    me,
    session,
    loadingContent,
    loadingContentError,
  } = state
  useEffect(() => {
    (async () => {
      if (me) {
        await initialize(displayId)
        await notifyStart()
      }
    })()
    return () => {
      notifyEnd()
    }
  }, [me, initialize, displayId, notifyStart, notifyEnd])
  const onJoinClick = useCallback(async () => {
    requestContentLoad()
  }, [requestContentLoad])
  const onLoginClick = useCallback(async () => {
    history.push(`/login?session=${displayId}`)
  }, [displayId])
  const onSignupClick = useCallback(async () => {
    history.push(`/login?signup&session=${displayId}`)
  }, [displayId])
  return (
    <div className={style.frame}>
      <div
        className={style.backgroundImage}
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className={style.gradientCover}></div>
      </div>
      {me ? (
        session && (
          <div className={style.content}>
            <div className={style.title}>{session.name}</div>
            <div className={style.description}>{session.description}</div>
            {loadingContent ? (
              <div className={style.loadingSpinner}>
                <CircularProgress />
              </div>
            ) : (
              <div className={style.buttonContainer}>
                <Button color="primary" variant="contained" onClick={onJoinClick}>
                  {loadingContentError ? "TRY AGAIN" : "JOIN"}
                  <div className={style.greenDot}></div>
                </Button>
              </div>
            )}
            {loadingContentError && (
              <div className={style.errorMessage}>Error occurred while loading the session. Please try again.</div>
            )}
            <div className={style.notes}>
              <p>This is the page to join the session.</p>
              <p>Please make sure that the title is correct.</p>
              <p>By clicking the join button, the session starts.</p>
            </div>
          </div>
        )
      ) : (
        <div className={style.loginNavigation}>
          <div className={style.icon}>
            <AccountIcon/>
          </div>
          <div className={style.message}>
            Required to login STYLY to join sessions.
          </div>
          <div className={style.buttonContainer}>
            <div className={style.row}>
              <Button color="primary" variant="contained" onClick={onLoginClick}>LOG IN</Button>
            </div>
            <div className={style.row}>
              <Button color="primary" variant="contained" onClick={onSignupClick}>SIGN UP</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default connector(SessionPage)
