import { createSlice, PayloadAction, createAsyncThunk, } from "@reduxjs/toolkit"
import { RootState, } from "app/store"
import axios from "axios"
import { REDUX_ACTION_TYPE_PREFIX, } from "app/constants"
import ApiClient from "utils/api"
import { API_BASE_PATH, AZURE_BASE_PATH, } from "app/env"
import { doGood, undoGood, addToMylist, removeFromMylist, logout, unfollow, } from "app/App/redux"
import User from "utils/api/models/User"
import SceneListItem from "utils/api/models/SceneListItem"

export enum TabType {
  // HOME = "home",
  MY_SCENE = "myscene",
  MY_LIST = "mylist",
  FOLLOWING = "following"
}

const ACTION_TYPE_PREFIX = `${REDUX_ACTION_TYPE_PREFIX}/MyPage`

const client = axios.create({
  withCredentials: true,
})

const FOLLOWEE_FETCH_LIMIT = 100

/**
 * 初期化のアクション
 */
export const initialize = createAsyncThunk<void, void>(
  `${ACTION_TYPE_PREFIX}/initialize`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const { me, } = state.app
    if (!me) {
      throw new Error("No logged in.")
    }
    // マイリストに登録したシーンのリストを取得
    thunkAPI.dispatch(fetchMylistedScenes())
    // 自身がフォローしているユーザのリストを取得
    thunkAPI.dispatch(fetchFollowList())
    // 自身が公開したシーンのリストを取得
    thunkAPI.dispatch(fetchMyScenes())
    // フォロワー数を取得
    thunkAPI.dispatch(fetchFollowerCount())
  }
)

/**
 * 自身がフォローしているユーザのリストを取得
 * V2のAPIができるまでの間、暫定でV1のAPIで全件取得しています
 */
export const fetchFollowList = createAsyncThunk<{ users: User[], total: number}, void>(
  `${ACTION_TYPE_PREFIX}/fetchFollowList`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const { me, } = state.app
    const { followList, } = state.myPage
    if (!me) {
      throw new Error("No logged in.")
    }
    const apiClient = new ApiClient(API_BASE_PATH)
    const result = await apiClient.getFolloweesByUserId(me.id, FOLLOWEE_FETCH_LIMIT, followList.length)
    return result
  }
)

/**
 * 自身がアップロードしたシーンのリストを取得
 */
export const fetchMyScenes = createAsyncThunk<SceneListItem[], void>(
  `${ACTION_TYPE_PREFIX}/fetchMyScenes`,
  async () => {
    const apiClient = new ApiClient(API_BASE_PATH)
    const result = await apiClient.getMyScenes()
    return result.scenes
  }
)

/**
 * マイリストに登録されているシーンを取得するアクション
 * V2のAPIができるまでの間、暫定でV1のAPIで全件取得しています
 */
export const fetchMylistedScenes = createAsyncThunk<SceneListItem[], void>(
  `${ACTION_TYPE_PREFIX}/fetchMylistedScenes`,
  async () => {
    interface ResponseData {mylist: [V1SceneResponse]}
    const result = await client.get<ResponseData>(`${API_BASE_PATH}/brand_user/mylist`)
    const mylist = result.data.mylist.map((scene) => {
      const newScene = convertV1toV2Scene(scene)
      // V1の戻り値にはmylistedフラグが含まれていないので立てる
      newScene.mylisted = true
      return newScene
    })

    return mylist
  }
)

/**
 * フォロワー数を取得するアクション
 */
const fetchFollowerCount = createAsyncThunk<number>(
  `${ACTION_TYPE_PREFIX}/fetchFollowerCount`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const { me, } = state.app
    if (!me) {
      throw new Error("No logged in.")
    }
    const apiClient = new ApiClient(API_BASE_PATH)
    const res = await apiClient.getFollowersByUserId(me.id)
    return res.total
  }
)

const initialState = {
  initialized: false,
  // 選択中のタブ
  tab: TabType.MY_SCENE as TabType,
  // マイリストに登録したシーンのリスト
  mylistedScenes: [] as SceneListItem[],
  fetchingMylistedScenes: false,
  // 自身がフォローしているユーザのリスト
  followList: [] as User[],
  totalFollowees: 0,
  fetchingFollowList: false,
  // 自身が公開したシーンのリスト
  myScenes: [] as SceneListItem[],
  fetchingMyScenes: false,
  // フォロワー数
  followerCount: 0,
}

export const slice = createSlice({
  name: ACTION_TYPE_PREFIX,
  initialState: initialState,
  reducers: {
    setTab: (state, action: PayloadAction<TabType>) => {
      return {
        ...state,
        tab: action.payload,
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(initialize.pending, (state) => {
        console.log(initialState)
        return {
          ...initialState,
        }
      })
      .addCase(initialize.fulfilled, (state) => {
        state.initialized = true
      })
      .addCase(fetchMyScenes.pending, (state) => {
        state.fetchingMyScenes = true
      })
      .addCase(fetchMyScenes.fulfilled, (state, action) => {
        // 自身がアップロードしたシーンのリストを更新
        state.myScenes = action.payload
        state.fetchingMyScenes = false
      })
      .addCase(fetchMylistedScenes.pending, (state) => {
        state.fetchingMylistedScenes = true
      })
      .addCase(fetchMylistedScenes.fulfilled, (state, action) => {
        // マイリストに登録されているシーンを更新
        state.mylistedScenes = action.payload
        state.fetchingMylistedScenes = false
      })
      .addCase(fetchFollowList.pending, (state) => {
        state.fetchingFollowList = true
      })
      .addCase(fetchFollowList.fulfilled, (state, action) => {
        // フォローリストを更新する
        const { users, total, } = action.payload
        state.followList = [...state.followList, ...users]
        state.totalFollowees = total
        state.fetchingFollowList = false
      })
      .addCase(doGood.fulfilled, (state, action) => {
        // いいね状況を更新する
        const sceneId = action.payload
        changeMylistedScenesGooded(true, state.mylistedScenes, sceneId)
        changeMyScenesGooded(true, state.myScenes, sceneId)
        return state
      })
      .addCase(undoGood.fulfilled, (state, action) => {
        // いいね状況を更新する
        const sceneId = action.payload
        changeMylistedScenesGooded(false, state.mylistedScenes, sceneId)
        changeMyScenesGooded(false, state.myScenes, sceneId)
        return state
      })
      .addCase(addToMylist.fulfilled, (state, action) => {
        // マイリスト状況を更新する
        const sceneId = action.payload.id
        changeMyScenesMylisted(true, state.myScenes, sceneId)
        return state
      })
      .addCase(removeFromMylist.fulfilled, (state, action) => {
        // マイリスト状況を更新する
        const sceneId = action.payload
        changeMyScenesMylisted(false, state.myScenes, sceneId)
        return state
      })
      .addCase(fetchFollowerCount.fulfilled, (state, action) => {
        // フォロワー数を更新する
        state.followerCount = action.payload
        return state
      })
      .addCase(logout.fulfilled, () => {
        // ログアウト後はstateを初期化する
        return initialState
      })
      .addCase(unfollow.fulfilled, (state, action) => {
        const userId = action.payload
        state.followList = state.followList.filter((user) => {
          return user.id !== userId
        })
      })
  },
})

/**
 * 自身が公開したシーンのリストに対して
 * sceneIdが指すシーンのマイリスト状況を変更する
 */
const changeMyScenesMylisted = (mylisted: boolean, myScenes: SceneListItem[], sceneId: string) => {
  for (let i = 0; i < myScenes.length; i++) {
    const scene = myScenes[i]
    if (scene.id !== sceneId) continue

    scene.mylisted = mylisted
    break
  }
}

/**
 * マイリストに登録したシーンのリストに対して
 * sceneIdが指すシーンのいいね状況を変更する
 */
const changeMylistedScenesGooded = (gooded: boolean, mylistedScenes: SceneListItem[], sceneId: string) => {
  for (let i = 0; i < mylistedScenes.length; i++) {
    const scene = mylistedScenes[i]
    if (scene.id !== sceneId) continue

    scene.gooded = gooded
    break
  }
}

/**
 * 自身が公開したシーンのリストに対して
 * sceneIdが指すシーンのいいね状況を変更する
 */
const changeMyScenesGooded = (gooded: boolean, myScenes: SceneListItem[], sceneId: string) => {
  for (let i = 0; i < myScenes.length; i++) {
    const scene = myScenes[i]
    if (scene.id !== sceneId) continue

    scene.gooded = gooded
    break
  }
}

export const selectState = (state: RootState) => state.myPage

export default slice.reducer

// api(v1)でのsceneの型
/* eslint camelcase: ["off", {ignoreDestructuring: true}] */
interface V1SceneResponse {
  sc_update_date: string
  sc_title: string
  sc_guid: string
  sc_description: string
  sc_thumbnail_photo: string
  bu_screen_name: string
  is_in_mylist: boolean
  sc_goods_count: number
  sc_did_good: boolean
  sc_bu_serial: number
  sc_access_restriction: number,
  sc_published: boolean,
  sc_reg_date: string,
}

/**
 * サムネイル画像用のURLを生成して返す
 * @param userSerial scene.user.id
 * @param sceneId scene.id
 * @param thumbnailPhotoId scene.sc_thumbnail_photo
 */
const getSceneThumbnailUrl = (userSerial: number, sceneId: string, thumbnailPhotoId: string) => {
  return `${AZURE_BASE_PATH}/styly-upload-bucket/${userSerial}/scenes/${sceneId}/photos/${thumbnailPhotoId}_middle.jpg`
}

function convertV1toV2Scene (v1Scene: V1SceneResponse): SceneListItem {
  return {
    id: v1Scene.sc_guid,
    user: {
      id: v1Scene.sc_bu_serial,
      username: v1Scene.bu_screen_name,
      profileImageUrl: null,
      facebookId: null,
      instagramId: null,
      twitterId: null,
      siteUrl: null,
      youtubeChannelUrl: null,
      followerCount: 0,
    },
    title: v1Scene.sc_title,
    thumbnail: {
      id: v1Scene.sc_thumbnail_photo,
      url: getSceneThumbnailUrl(v1Scene.sc_bu_serial, v1Scene.sc_guid, v1Scene.sc_thumbnail_photo),
      width: 960,
      height: 540,
    },
    createdAt: v1Scene.sc_reg_date,
    updatedAt: v1Scene.sc_update_date,
    publishedAt: "2000-01-01T00:00:00", // V1のレスポンスにはこの項目が無い
    gooded: v1Scene.sc_did_good,
    mylisted: v1Scene.is_in_mylist,
    accessLevel: v1Scene.sc_access_restriction,
    published: v1Scene.sc_published,
  } as SceneListItem
}
