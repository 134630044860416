import React, { useEffect, useRef, } from "react"
import ReactMarkdown from "react-markdown"
import hljs from "utils/highlight"
import "highlight.js/styles/monokai-sublime.css"
import style from "./style.module.scss"

type CodeBlockProps = {
  inline?: boolean
  className?: string
  children: React.ReactNode
}

function CodeBlock ({ inline, className, children, }: CodeBlockProps) {
  const elementRef = useRef<HTMLDivElement>(null)

  const match = /language-(\w+)/.exec(className || "")
  const language = match ? match[1] : null

  useEffect(() => {
    if (elementRef.current) {
      hljs.highlightBlock(elementRef.current)
    }
  }, [])

  return inline ? (
    <code>{children}</code>
  ) : (
    <code ref={elementRef} className={`language-${language}`}>
      {children}
    </code>
  )
}

type MarkdownProps = {
  source: string,
  onClickLink?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

export default function Markdown ({ source, onClickLink, }: MarkdownProps) {
  const onClickLinkInternal = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement
    if (target.tagName === "A") {
      e.preventDefault()
      onClickLink && onClickLink(e as React.MouseEvent<HTMLAnchorElement>)
    }
  }
  return (
    <div className={style.wrapper} onClickCapture={onClickLinkInternal}>
      <ReactMarkdown
        components={{
          code: CodeBlock,
        }}
      >{source}</ReactMarkdown>
    </div>
  )
}
