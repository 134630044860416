/* tslint:disable */
/* eslint-disable */
/**
 * STYLY API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * @type AddFollowResponse
 * @export
 */
export type AddFollowResponse = AddFollowSuccess | string;

/**
 * 
 * @export
 * @interface AddFollowSuccess
 */
export interface AddFollowSuccess {
    /**
     * 
     * @type {number}
     * @memberof AddFollowSuccess
     */
    'count': number;
}
/**
 * @type AddMylistResponse
 * @export
 */
export type AddMylistResponse = AddMylistSuccess | string;

/**
 * 
 * @export
 * @interface AddMylistSuccess
 */
export interface AddMylistSuccess {
    /**
     * 
     * @type {number}
     * @memberof AddMylistSuccess
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'sa_styly_guid': string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'sa_bu_serial': number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'sa_reg_date': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'sa_update_date': string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'sa_public_flag': number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'sa_title': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'sa_description': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'sa_meta_json': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'sa_data_type': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'sa_unity_guid': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'sa_unity_version': string;
}
/**
 * 
 * @export
 * @interface Conversion400Response
 */
export interface Conversion400Response {
    /**
     * 
     * @type {string}
     * @memberof Conversion400Response
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Conversion400Response
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface ConversionAsset
 */
export interface ConversionAsset {
    /**
     * 
     * @type {number}
     * @memberof ConversionAsset
     */
    'cts_serial': number;
    /**
     * 
     * @type {number}
     * @memberof ConversionAsset
     */
    'cts_bu_serial': number;
    /**
     * 
     * @type {string}
     * @memberof ConversionAsset
     */
    'cts_reg_date': string;
    /**
     * 
     * @type {string}
     * @memberof ConversionAsset
     */
    'cts_send_datetime': string;
    /**
     * 
     * @type {string}
     * @memberof ConversionAsset
     */
    'cts_receive_datetime': string;
    /**
     * 
     * @type {string}
     * @memberof ConversionAsset
     */
    'cts_styly_guid': string;
    /**
     * 
     * @type {string}
     * @memberof ConversionAsset
     */
    'cts_title': string;
    /**
     * 
     * @type {string}
     * @memberof ConversionAsset
     */
    'cts_memo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversionAsset
     */
    'cts_original_filetype': string;
    /**
     * 
     * @type {string}
     * @memberof ConversionAsset
     */
    'cts_original_file_to_convert': string;
    /**
     * 
     * @type {string}
     * @memberof ConversionAsset
     */
    'cts_meta_3dmodel'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversionAsset
     */
    'cts_meta_upload_machine': string;
    /**
     * 
     * @type {string}
     * @memberof ConversionAsset
     */
    'cts_meta_processing_machine'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConversionAsset
     */
    'cts_status': number;
    /**
     * 
     * @type {number}
     * @memberof ConversionAsset
     */
    'cts_sketchfab_uid'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConversionAsset
     */
    'cts_files_info_original': string;
    /**
     * 
     * @type {string}
     * @memberof ConversionAsset
     */
    'cts_files_info_converted'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversionAsset
     */
    'cts_unity_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversionAsset
     */
    'cts_converter_job_id'?: string;
}
/**
 * 
 * @export
 * @interface CopySceneAsLogginUser200Response
 */
export interface CopySceneAsLogginUser200Response {
    /**
     * 
     * @type {string}
     * @memberof CopySceneAsLogginUser200Response
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CreateAssetConversionRequest
 */
export interface CreateAssetConversionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAssetConversionRequest
     */
    'guid': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAssetConversionRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAssetConversionRequest
     */
    'original_filetype': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAssetConversionRequest
     */
    'original_filename': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAssetConversionRequest
     */
    'original_fileinfo': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAssetConversionRequest
     */
    'client_info': string;
}
/**
 * 
 * @export
 * @interface CreateConversionAssetRequest
 */
export interface CreateConversionAssetRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateConversionAssetRequest
     */
    'guid': string;
    /**
     * 
     * @type {string}
     * @memberof CreateConversionAssetRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateConversionAssetRequest
     */
    'original_filetype': string;
    /**
     * 
     * @type {string}
     * @memberof CreateConversionAssetRequest
     */
    'original_filename': string;
    /**
     * 
     * @type {string}
     * @memberof CreateConversionAssetRequest
     */
    'original_fileinfo': string;
    /**
     * 
     * @type {string}
     * @memberof CreateConversionAssetRequest
     */
    'client_info': string;
}
/**
 * 
 * @export
 * @interface CreateSessionRequest
 */
export interface CreateSessionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateSessionRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSessionRequest
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSessionRequest
     */
    'scene_guid_list'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Creator
 */
export interface Creator {
    /**
     * 
     * @type {number}
     * @memberof Creator
     */
    'bu_serial': number;
    /**
     * 
     * @type {string}
     * @memberof Creator
     */
    'bu_screen_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Creator
     */
    'site_url': string;
    /**
     * 
     * @type {string}
     * @memberof Creator
     */
    'twitter_id': string;
    /**
     * 
     * @type {string}
     * @memberof Creator
     */
    'instagram_id': string;
    /**
     * 
     * @type {string}
     * @memberof Creator
     */
    'facebook_id': string;
    /**
     * 
     * @type {string}
     * @memberof Creator
     */
    'youtube_channel_url': string;
    /**
     * 
     * @type {string}
     * @memberof Creator
     */
    'profile_thumbnail': string;
    /**
     * 
     * @type {number}
     * @memberof Creator
     */
    'bu_business_plan': number;
    /**
     * 
     * @type {number}
     * @memberof Creator
     */
    'follower_count': number;
}
/**
 * 
 * @export
 * @interface CreatorScene
 */
export interface CreatorScene {
    /**
     * 
     * @type {number}
     * @memberof CreatorScene
     */
    'sc_serial': number;
    /**
     * 
     * @type {string}
     * @memberof CreatorScene
     */
    'sc_reg_date': string;
    /**
     * 
     * @type {string}
     * @memberof CreatorScene
     */
    'sc_update_date': string;
    /**
     * 
     * @type {number}
     * @memberof CreatorScene
     */
    'sc_bu_serial': number;
    /**
     * 
     * @type {string}
     * @memberof CreatorScene
     */
    'sc_title': string;
    /**
     * 
     * @type {number}
     * @memberof CreatorScene
     */
    'sc_unlisted': number;
    /**
     * 
     * @type {number}
     * @memberof CreatorScene
     */
    'sc_published': number;
    /**
     * 
     * @type {string}
     * @memberof CreatorScene
     */
    'sc_guid': string;
    /**
     * 
     * @type {number}
     * @memberof CreatorScene
     */
    'sc_rating': number;
    /**
     * 
     * @type {string}
     * @memberof CreatorScene
     */
    'sc_photos': string;
    /**
     * 
     * @type {string}
     * @memberof CreatorScene
     */
    'sc_description': string;
    /**
     * 
     * @type {string}
     * @memberof CreatorScene
     */
    'sc_thumbnail_photo': string;
    /**
     * 
     * @type {string}
     * @memberof CreatorScene
     */
    'sc_good': string;
    /**
     * 
     * @type {string}
     * @memberof CreatorScene
     */
    'sc_comments': string;
    /**
     * 
     * @type {number}
     * @memberof CreatorScene
     */
    'sc_category_serial': number;
    /**
     * 
     * @type {number}
     * @memberof CreatorScene
     */
    'sc_access_restriction': number;
    /**
     * 
     * @type {number}
     * @memberof CreatorScene
     */
    'sc_goods_count': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreatorScene
     */
    'sc_did_good': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreatorScene
     */
    'sc_comments_count': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreatorScene
     */
    'is_in_mylist': boolean;
}
/**
 * 
 * @export
 * @interface ExitSessionResponse
 */
export interface ExitSessionResponse {
    /**
     * 
     * @type {number}
     * @memberof ExitSessionResponse
     */
    'status': number;
}
/**
 * 
 * @export
 * @interface FollowList
 */
export interface FollowList {
    /**
     * 
     * @type {number}
     * @memberof FollowList
     */
    'follow_bu_serial': number;
    /**
     * 
     * @type {string}
     * @memberof FollowList
     */
    'bu_screen_name': string;
    /**
     * 
     * @type {string}
     * @memberof FollowList
     */
    'profile_thumbnail': string;
    /**
     * 
     * @type {number}
     * @memberof FollowList
     */
    'follower_count': number;
}
/**
 * 
 * @export
 * @interface FollowScene
 */
export interface FollowScene {
    /**
     * 
     * @type {number}
     * @memberof FollowScene
     */
    'sc_serial': number;
    /**
     * 
     * @type {string}
     * @memberof FollowScene
     */
    'sc_reg_date': string;
    /**
     * 
     * @type {string}
     * @memberof FollowScene
     */
    'sc_update_date': string;
    /**
     * 
     * @type {number}
     * @memberof FollowScene
     */
    'sc_bu_serial': number;
    /**
     * 
     * @type {string}
     * @memberof FollowScene
     */
    'sc_title': string;
    /**
     * 
     * @type {number}
     * @memberof FollowScene
     */
    'sc_unlisted': number;
    /**
     * 
     * @type {number}
     * @memberof FollowScene
     */
    'sc_published': number;
    /**
     * 
     * @type {string}
     * @memberof FollowScene
     */
    'sc_guid': string;
    /**
     * 
     * @type {number}
     * @memberof FollowScene
     */
    'sc_rating': number;
    /**
     * 
     * @type {string}
     * @memberof FollowScene
     */
    'sc_photos': string;
    /**
     * 
     * @type {string}
     * @memberof FollowScene
     */
    'sc_description': string;
    /**
     * 
     * @type {string}
     * @memberof FollowScene
     */
    'sc_thumbnail_photo': string;
    /**
     * 
     * @type {string}
     * @memberof FollowScene
     */
    'sc_good': string;
    /**
     * 
     * @type {string}
     * @memberof FollowScene
     */
    'sc_comments': string;
    /**
     * 
     * @type {string}
     * @memberof FollowScene
     */
    'bu_screen_name': string;
    /**
     * 
     * @type {number}
     * @memberof FollowScene
     */
    'sc_category_serial': number;
    /**
     * 
     * @type {number}
     * @memberof FollowScene
     */
    'sc_goods_count': number;
    /**
     * 
     * @type {boolean}
     * @memberof FollowScene
     */
    'sc_did_good': boolean;
    /**
     * 
     * @type {number}
     * @memberof FollowScene
     */
    'sc_comments_count': number;
    /**
     * 
     * @type {boolean}
     * @memberof FollowScene
     */
    'is_in_mylist': boolean;
}
/**
 * 
 * @export
 * @interface GetCreateListResponse
 */
export interface GetCreateListResponse {
    /**
     * 
     * @type {Array<FollowScene>}
     * @memberof GetCreateListResponse
     */
    'scenes': Array<FollowScene>;
}
/**
 * 
 * @export
 * @interface GetCreatorSceneResponse
 */
export interface GetCreatorSceneResponse {
    /**
     * 
     * @type {Creator}
     * @memberof GetCreatorSceneResponse
     */
    'creator': Creator;
    /**
     * 
     * @type {Array<CreatorScene>}
     * @memberof GetCreatorSceneResponse
     */
    'scenes': Array<CreatorScene>;
}
/**
 * 
 * @export
 * @interface GetFollowListResponse
 */
export interface GetFollowListResponse {
    /**
     * 
     * @type {Array<FollowList>}
     * @memberof GetFollowListResponse
     */
    'follows': Array<FollowList>;
}
/**
 * @type GetMylistResponse
 * @export
 */
export type GetMylistResponse = GetMylistSuccess | string;

/**
 * 
 * @export
 * @interface GetMylistScene
 */
export interface GetMylistScene {
    /**
     * 
     * @type {number}
     * @memberof GetMylistScene
     */
    'sc_comments': number;
    /**
     * 
     * @type {string}
     * @memberof GetMylistScene
     */
    'sc_good': string;
    /**
     * 
     * @type {string}
     * @memberof GetMylistScene
     */
    'bu_screen_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMylistScene
     */
    'sc_serial': number;
    /**
     * 
     * @type {string}
     * @memberof GetMylistScene
     */
    'sc_reg_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetMylistScene
     */
    'sc_update_date': string;
    /**
     * 
     * @type {number}
     * @memberof GetMylistScene
     */
    'sc_bu_serial': number;
    /**
     * 
     * @type {string}
     * @memberof GetMylistScene
     */
    'sc_title': string;
    /**
     * 
     * @type {string}
     * @memberof GetMylistScene
     */
    'sc_xml': string;
    /**
     * 
     * @type {number}
     * @memberof GetMylistScene
     */
    'sc_unlisted': number;
    /**
     * 
     * @type {number}
     * @memberof GetMylistScene
     */
    'sc_published': number;
    /**
     * 
     * @type {string}
     * @memberof GetMylistScene
     */
    'sc_guid': string;
    /**
     * 
     * @type {number}
     * @memberof GetMylistScene
     */
    'sc_rating': number;
    /**
     * 
     * @type {string}
     * @memberof GetMylistScene
     */
    'sc_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetMylistScene
     */
    'sc_photos': string;
    /**
     * 
     * @type {string}
     * @memberof GetMylistScene
     */
    'sc_thumbnail_photo': string;
    /**
     * 
     * @type {number}
     * @memberof GetMylistScene
     */
    'sc_category_serial': number;
    /**
     * 
     * @type {number}
     * @memberof GetMylistScene
     */
    'sc_goods_count': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetMylistScene
     */
    'sc_did_good': boolean;
    /**
     * 
     * @type {number}
     * @memberof GetMylistScene
     */
    'sc_comments_count': number;
}
/**
 * 
 * @export
 * @interface GetMylistSceneAllOf
 */
export interface GetMylistSceneAllOf {
    /**
     * 
     * @type {number}
     * @memberof GetMylistSceneAllOf
     */
    'sc_goods_count': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetMylistSceneAllOf
     */
    'sc_did_good': boolean;
    /**
     * 
     * @type {number}
     * @memberof GetMylistSceneAllOf
     */
    'sc_comments_count': number;
}
/**
 * 
 * @export
 * @interface GetMylistSuccess
 */
export interface GetMylistSuccess {
    /**
     * 
     * @type {Array<GetMylistSuccessMylistInner>}
     * @memberof GetMylistSuccess
     */
    'mylist': Array<GetMylistSuccessMylistInner>;
}
/**
 * 
 * @export
 * @interface GetMylistSuccessMylistInner
 */
export interface GetMylistSuccessMylistInner {
    /**
     * 
     * @type {number}
     * @memberof GetMylistSuccessMylistInner
     */
    'sc_comments': number;
    /**
     * 
     * @type {string}
     * @memberof GetMylistSuccessMylistInner
     */
    'sc_good': string;
    /**
     * 
     * @type {string}
     * @memberof GetMylistSuccessMylistInner
     */
    'bu_screen_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMylistSuccessMylistInner
     */
    'sc_serial': number;
    /**
     * 
     * @type {string}
     * @memberof GetMylistSuccessMylistInner
     */
    'sc_reg_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetMylistSuccessMylistInner
     */
    'sc_update_date': string;
    /**
     * 
     * @type {number}
     * @memberof GetMylistSuccessMylistInner
     */
    'sc_bu_serial': number;
    /**
     * 
     * @type {string}
     * @memberof GetMylistSuccessMylistInner
     */
    'sc_title': string;
    /**
     * 
     * @type {string}
     * @memberof GetMylistSuccessMylistInner
     */
    'sc_xml': string;
    /**
     * 
     * @type {number}
     * @memberof GetMylistSuccessMylistInner
     */
    'sc_unlisted': number;
    /**
     * 
     * @type {number}
     * @memberof GetMylistSuccessMylistInner
     */
    'sc_published': number;
    /**
     * 
     * @type {string}
     * @memberof GetMylistSuccessMylistInner
     */
    'sc_guid': string;
    /**
     * 
     * @type {number}
     * @memberof GetMylistSuccessMylistInner
     */
    'sc_rating': number;
    /**
     * 
     * @type {string}
     * @memberof GetMylistSuccessMylistInner
     */
    'sc_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetMylistSuccessMylistInner
     */
    'sc_photos': string;
    /**
     * 
     * @type {string}
     * @memberof GetMylistSuccessMylistInner
     */
    'sc_thumbnail_photo': string;
    /**
     * 
     * @type {number}
     * @memberof GetMylistSuccessMylistInner
     */
    'sc_category_serial': number;
    /**
     * 
     * @type {number}
     * @memberof GetMylistSuccessMylistInner
     */
    'sc_goods_count': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetMylistSuccessMylistInner
     */
    'sc_did_good': boolean;
    /**
     * 
     * @type {number}
     * @memberof GetMylistSuccessMylistInner
     */
    'sc_comments_count': number;
}
/**
 * 
 * @export
 * @interface GetScene
 */
export interface GetScene {
    /**
     * 
     * @type {number}
     * @memberof GetScene
     */
    'sc_comments': number;
    /**
     * 
     * @type {string}
     * @memberof GetScene
     */
    'sc_good': string;
    /**
     * 
     * @type {string}
     * @memberof GetScene
     */
    'bu_screen_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetScene
     */
    'sc_serial': number;
    /**
     * 
     * @type {string}
     * @memberof GetScene
     */
    'sc_reg_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetScene
     */
    'sc_update_date': string;
    /**
     * 
     * @type {number}
     * @memberof GetScene
     */
    'sc_bu_serial': number;
    /**
     * 
     * @type {string}
     * @memberof GetScene
     */
    'sc_title': string;
    /**
     * 
     * @type {string}
     * @memberof GetScene
     */
    'sc_xml': string;
    /**
     * 
     * @type {number}
     * @memberof GetScene
     */
    'sc_unlisted': number;
    /**
     * 
     * @type {number}
     * @memberof GetScene
     */
    'sc_published': number;
    /**
     * 
     * @type {string}
     * @memberof GetScene
     */
    'sc_guid': string;
    /**
     * 
     * @type {number}
     * @memberof GetScene
     */
    'sc_rating': number;
    /**
     * 
     * @type {string}
     * @memberof GetScene
     */
    'sc_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetScene
     */
    'sc_photos': string;
    /**
     * 
     * @type {string}
     * @memberof GetScene
     */
    'sc_thumbnail_photo': string;
    /**
     * 
     * @type {number}
     * @memberof GetScene
     */
    'sc_category_serial': number;
}
/**
 * 
 * @export
 * @interface GetSceneResponse
 */
export interface GetSceneResponse {
    /**
     * 
     * @type {number}
     * @memberof GetSceneResponse
     */
    'sc_comments': number;
    /**
     * 
     * @type {string}
     * @memberof GetSceneResponse
     */
    'sc_good': string;
    /**
     * 
     * @type {string}
     * @memberof GetSceneResponse
     */
    'bu_screen_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetSceneResponse
     */
    'sc_serial': number;
    /**
     * 
     * @type {string}
     * @memberof GetSceneResponse
     */
    'sc_reg_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetSceneResponse
     */
    'sc_update_date': string;
    /**
     * 
     * @type {number}
     * @memberof GetSceneResponse
     */
    'sc_bu_serial': number;
    /**
     * 
     * @type {string}
     * @memberof GetSceneResponse
     */
    'sc_title': string;
    /**
     * 
     * @type {string}
     * @memberof GetSceneResponse
     */
    'sc_xml': string;
    /**
     * 
     * @type {number}
     * @memberof GetSceneResponse
     */
    'sc_unlisted': number;
    /**
     * 
     * @type {number}
     * @memberof GetSceneResponse
     */
    'sc_published': number;
    /**
     * 
     * @type {string}
     * @memberof GetSceneResponse
     */
    'sc_guid': string;
    /**
     * 
     * @type {number}
     * @memberof GetSceneResponse
     */
    'sc_rating': number;
    /**
     * 
     * @type {string}
     * @memberof GetSceneResponse
     */
    'sc_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetSceneResponse
     */
    'sc_photos': string;
    /**
     * 
     * @type {string}
     * @memberof GetSceneResponse
     */
    'sc_thumbnail_photo': string;
    /**
     * 
     * @type {number}
     * @memberof GetSceneResponse
     */
    'sc_category_serial': number;
    /**
     * 
     * @type {number}
     * @memberof GetSceneResponse
     */
    'sc_goods_count': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetSceneResponse
     */
    'sc_did_good': boolean;
    /**
     * 
     * @type {number}
     * @memberof GetSceneResponse
     */
    'sc_comments_count': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetSceneResponse
     */
    'is_in_mylist': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSceneResponse
     */
    'custom_logo_url': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSceneResponse
     */
    'permittedUsernames'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof GetSceneResponse
     */
    'sc_access_restriction': number;
    /**
     * 
     * @type {string}
     * @memberof GetSceneResponse
     */
    'sc_photos_webeditor': string;
}
/**
 * 
 * @export
 * @interface GetSceneResponseAllOf
 */
export interface GetSceneResponseAllOf {
    /**
     * 
     * @type {number}
     * @memberof GetSceneResponseAllOf
     */
    'sc_goods_count': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetSceneResponseAllOf
     */
    'sc_did_good': boolean;
    /**
     * 
     * @type {number}
     * @memberof GetSceneResponseAllOf
     */
    'sc_comments_count': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetSceneResponseAllOf
     */
    'is_in_mylist': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSceneResponseAllOf
     */
    'custom_logo_url': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSceneResponseAllOf
     */
    'permittedUsernames'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof GetSceneResponseAllOf
     */
    'sc_access_restriction': number;
    /**
     * 
     * @type {string}
     * @memberof GetSceneResponseAllOf
     */
    'sc_photos_webeditor': string;
}
/**
 * 
 * @export
 * @interface GetScenes
 */
export interface GetScenes {
    /**
     * 
     * @type {number}
     * @memberof GetScenes
     */
    'sc_comments': number;
    /**
     * 
     * @type {string}
     * @memberof GetScenes
     */
    'sc_good': string;
    /**
     * 
     * @type {string}
     * @memberof GetScenes
     */
    'bu_screen_name': string;
    /**
     * 
     * @type {number}
     * @memberof GetScenes
     */
    'sc_serial': number;
    /**
     * 
     * @type {string}
     * @memberof GetScenes
     */
    'sc_reg_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetScenes
     */
    'sc_update_date': string;
    /**
     * 
     * @type {number}
     * @memberof GetScenes
     */
    'sc_bu_serial': number;
    /**
     * 
     * @type {string}
     * @memberof GetScenes
     */
    'sc_title': string;
    /**
     * 
     * @type {string}
     * @memberof GetScenes
     */
    'sc_xml': string;
    /**
     * 
     * @type {number}
     * @memberof GetScenes
     */
    'sc_unlisted': number;
    /**
     * 
     * @type {number}
     * @memberof GetScenes
     */
    'sc_published': number;
    /**
     * 
     * @type {string}
     * @memberof GetScenes
     */
    'sc_guid': string;
    /**
     * 
     * @type {number}
     * @memberof GetScenes
     */
    'sc_rating': number;
    /**
     * 
     * @type {string}
     * @memberof GetScenes
     */
    'sc_description': string;
    /**
     * 
     * @type {string}
     * @memberof GetScenes
     */
    'sc_photos': string;
    /**
     * 
     * @type {string}
     * @memberof GetScenes
     */
    'sc_thumbnail_photo': string;
    /**
     * 
     * @type {number}
     * @memberof GetScenes
     */
    'sc_category_serial': number;
    /**
     * 
     * @type {number}
     * @memberof GetScenes
     */
    'sc_goods_count': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetScenes
     */
    'sc_did_good': boolean;
    /**
     * 
     * @type {number}
     * @memberof GetScenes
     */
    'sc_comments_count': number;
}
/**
 * 
 * @export
 * @interface GetScenesAllOf
 */
export interface GetScenesAllOf {
    /**
     * 
     * @type {string}
     * @memberof GetScenesAllOf
     */
    'bu_screen_name': string;
    /**
     * 
     * @type {string}
     * @memberof GetScenesAllOf
     */
    'sc_xml'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetScenesAllOf
     */
    'sc_goods_count': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetScenesAllOf
     */
    'sc_did_good': boolean;
    /**
     * 
     * @type {number}
     * @memberof GetScenesAllOf
     */
    'sc_comments_count': number;
}
/**
 * 
 * @export
 * @interface GetSignedUrlForAsset200Response
 */
export interface GetSignedUrlForAsset200Response {
    /**
     * 
     * @type {string}
     * @memberof GetSignedUrlForAsset200Response
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface GetSignedUrlForScene200Response
 */
export interface GetSignedUrlForScene200Response {
    /**
     * 
     * @type {string}
     * @memberof GetSignedUrlForScene200Response
     */
    'url_thumbnail2d_jpg'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSignedUrlForScene200Response
     */
    'url_scene360stereo_jpg'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSignedUrlForScene200Response
     */
    'url_scene2d_jpg'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSignedUrlForScene200Response
     */
    'url_scene_xml'?: string;
}
/**
 * 
 * @export
 * @interface GetSignedUrlForUserfile200Response
 */
export interface GetSignedUrlForUserfile200Response {
    /**
     * 
     * @type {string}
     * @memberof GetSignedUrlForUserfile200Response
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSignedUrlForUserfile200Response
     */
    'uploaded_url'?: string;
}
/**
 * 
 * @export
 * @interface GetSignedUrlsForUnityPluginRequest
 */
export interface GetSignedUrlsForUnityPluginRequest {
    /**
     * 
     * @type {string}
     * @memberof GetSignedUrlsForUnityPluginRequest
     */
    'bu_email': string;
    /**
     * 
     * @type {string}
     * @memberof GetSignedUrlsForUnityPluginRequest
     */
    'bu_api_key': string;
    /**
     * 
     * @type {string}
     * @memberof GetSignedUrlsForUnityPluginRequest
     */
    'sa_unity_version': string;
    /**
     * 
     * @type {string}
     * @memberof GetSignedUrlsForUnityPluginRequest
     */
    'sa_unity_guid': string;
}
/**
 * 
 * @export
 * @interface GetSignedUrlsForUnityPluginResponse
 */
export interface GetSignedUrlsForUnityPluginResponse {
    /**
     * 
     * @type {string}
     * @memberof GetSignedUrlsForUnityPluginResponse
     */
    'styly_guid': string;
    /**
     * 
     * @type {string}
     * @memberof GetSignedUrlsForUnityPluginResponse
     */
    'url_package': string;
    /**
     * 
     * @type {string}
     * @memberof GetSignedUrlsForUnityPluginResponse
     */
    'url_thumbnail': string;
    /**
     * 
     * @type {string}
     * @memberof GetSignedUrlsForUnityPluginResponse
     */
    'styly_hash': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSignedUrlsForUnityPluginResponse
     */
    'platforms': Array<string>;
}
/**
 * 
 * @export
 * @interface GetUploadFileResponse
 */
export interface GetUploadFileResponse {
    /**
     * 
     * @type {number}
     * @memberof GetUploadFileResponse
     */
    'bu_serial': number;
    /**
     * 
     * @type {string}
     * @memberof GetUploadFileResponse
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUploadFileResponse
     */
    'music'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUploadFileResponse
     */
    'video'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUploadFileResponse
     */
    'pdf'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUploadFileResponse
     */
    'reg_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetUploadFileResponse
     */
    'update_date': string;
}
/**
 * 
 * @export
 * @interface IsFollowedResponse
 */
export interface IsFollowedResponse {
    /**
     * 
     * @type {boolean}
     * @memberof IsFollowedResponse
     */
    'is_followed': boolean;
}
/**
 * 
 * @export
 * @interface JoinSessionResponse
 */
export interface JoinSessionResponse {
    /**
     * 
     * @type {string}
     * @memberof JoinSessionResponse
     */
    'global_ip': string;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    'bu_serial': number;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'updated_at': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Location
     */
    'scene_guids': Array<string>;
    /**
     * 
     * @type {Array<Scene>}
     * @memberof Location
     */
    'scenes': Array<Scene>;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'country'?: string;
}
/**
 * 
 * @export
 * @interface LoginUser
 */
export interface LoginUser {
    /**
     * 
     * @type {number}
     * @memberof LoginUser
     */
    'bu_serial': number;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'bu_reg_date': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'bu_update_date': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'bu_email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'bu_api_key': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'bu_company_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'bu_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'bu_last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'bu_screen_name': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'bu_last_login_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'bu_reg_country': string;
    /**
     * 
     * @type {number}
     * @memberof LoginUser
     */
    'bu_business_plan': number;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'site_url': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'twitter_id': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'instagram_id': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'facebook_id': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'youtube_channel_url': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'profile_thumbnail': string;
    /**
     * 
     * @type {number}
     * @memberof LoginUser
     */
    'bu_available_restriction_operations': number;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'bu_plan_transaction_hash'?: string;
    /**
     * 
     * @type {LoginUserFeatures}
     * @memberof LoginUser
     */
    'features'?: LoginUserFeatures;
}
/**
 * 
 * @export
 * @interface LoginUserFeatures
 */
export interface LoginUserFeatures {
    /**
     * 
     * @type {boolean}
     * @memberof LoginUserFeatures
     */
    'is_new_scene_unlisted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoginUserFeatures
     */
    'no_service_logo_display'?: boolean;
}
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'gn_serial': number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'gn_reg_date': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'gn_update_date': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'gn_sc_guid': string;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'gn_to_bu_serial': number;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'gn_from_bu_serial': number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'gn_bu_screen_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'gn_good': number;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'gn_comment': number;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'gn_viewed': number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'gn_sc_title': string;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'gn_follow': number;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'gn_mylist': number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'profile_thumbnail': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'sc_thumbnail_photo': string;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    'is_followed'?: boolean | null;
}
/**
 * 
 * @export
 * @interface RestrictionOperationsResponse
 */
export interface RestrictionOperationsResponse {
    /**
     * 
     * @type {number}
     * @memberof RestrictionOperationsResponse
     */
    'availableRestrictionOperations': number;
}
/**
 * 
 * @export
 * @interface SaveSceneDBError
 */
export interface SaveSceneDBError {
    /**
     * 
     * @type {string}
     * @memberof SaveSceneDBError
     */
    'ok': string;
    /**
     * 
     * @type {string}
     * @memberof SaveSceneDBError
     */
    'sc_guid': string;
    /**
     * 
     * @type {string}
     * @memberof SaveSceneDBError
     */
    'sc_xml'?: string;
    /**
     * 
     * @type {number}
     * @memberof SaveSceneDBError
     */
    'bu_serial'?: number;
}
/**
 * 
 * @export
 * @interface SaveSceneRequest
 */
export interface SaveSceneRequest {
    /**
     * 
     * @type {string}
     * @memberof SaveSceneRequest
     */
    'sc_guid'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveSceneRequest
     */
    'sc_title': string;
    /**
     * 
     * @type {string}
     * @memberof SaveSceneRequest
     */
    'sc_xml'?: string;
}
/**
 * @type SaveSceneResponse
 * @export
 */
export type SaveSceneResponse = SaveSceneDBError | SaveSceneSuccess;

/**
 * 
 * @export
 * @interface SaveSceneSuccess
 */
export interface SaveSceneSuccess {
    /**
     * 
     * @type {Scene}
     * @memberof SaveSceneSuccess
     */
    'ok': Scene;
    /**
     * 
     * @type {string}
     * @memberof SaveSceneSuccess
     */
    'sc_guid': string;
}
/**
 * 
 * @export
 * @interface Scene
 */
export interface Scene {
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    'id': string;
    /**
     * 
     * @type {User}
     * @memberof Scene
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    'titl:e'?: string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    'thumbnail_tiny_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    'thumbnail_small_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    'thumbnail_middle_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    'thumbnail_large_url'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Scene
     */
    'published': boolean;
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    'access_level': number;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    'published_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof Scene
     */
    'gooded': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Scene
     */
    'mylisted': boolean;
}
/**
 * 
 * @export
 * @interface SceneComment
 */
export interface SceneComment {
    /**
     * 
     * @type {string}
     * @memberof SceneComment
     */
    'bu_screen_name': string;
    /**
     * 
     * @type {string}
     * @memberof SceneComment
     */
    'profile_thumbnail': string | null;
    /**
     * 
     * @type {string}
     * @memberof SceneComment
     */
    'sm_screen_shot'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SceneComment
     */
    'sm_serial': number;
    /**
     * 
     * @type {string}
     * @memberof SceneComment
     */
    'sm_reg_date': string;
    /**
     * 
     * @type {string}
     * @memberof SceneComment
     */
    'sm_update_date': string;
    /**
     * 
     * @type {number}
     * @memberof SceneComment
     */
    'sm_bu_serial': number;
    /**
     * 
     * @type {string}
     * @memberof SceneComment
     */
    'sm_comment': string;
}
/**
 * 
 * @export
 * @interface Session
 */
export interface Session {
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'display_id': string;
    /**
     * 
     * @type {number}
     * @memberof Session
     */
    'bu_serial': number;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof Session
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'global_ip': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Session
     */
    'scene_guid_list': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface SessionList
 */
export interface SessionList {
    /**
     * 
     * @type {Array<Session>}
     * @memberof SessionList
     */
    'session_list': Array<Session>;
}
/**
 * 
 * @export
 * @interface SignupRequest
 */
export interface SignupRequest {
    /**
     * 
     * @type {string}
     * @memberof SignupRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SignupRequest
     */
    'screenName': string;
    /**
     * 
     * @type {string}
     * @memberof SignupRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface SignupResponse
 */
export interface SignupResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SignupResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {number}
     * @memberof SignupResponse
     */
    'bu_serial': number;
}
/**
 * 
 * @export
 * @interface SuccessResponse
 */
export interface SuccessResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SuccessResponse
     */
    'ok': boolean;
}
/**
 * 
 * @export
 * @interface UnityPluginCompleteRequest
 */
export interface UnityPluginCompleteRequest {
    /**
     * 
     * @type {string}
     * @memberof UnityPluginCompleteRequest
     */
    'bu_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UnityPluginCompleteRequest
     */
    'bu_api_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof UnityPluginCompleteRequest
     */
    'sa_unity_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof UnityPluginCompleteRequest
     */
    'sa_unity_guid'?: string;
    /**
     * 
     * @type {string}
     * @memberof UnityPluginCompleteRequest
     */
    'sa_title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UnityPluginCompleteRequest
     */
    'styly_hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof UnityPluginCompleteRequest
     */
    'styly_guid'?: string;
}
/**
 * 
 * @export
 * @interface UpdateLocationRequest
 */
export interface UpdateLocationRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationRequest
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateLocationRequest
     */
    'scene_guids': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateSessionRequest
 */
export interface UpdateSessionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSessionRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSessionRequest
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSessionRequest
     */
    'scene_guid_list'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateStateAssetConversionRequest
 */
export interface UpdateStateAssetConversionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateStateAssetConversionRequest
     */
    'jobId': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateStateAssetConversionRequest
     */
    'state': number;
}
/**
 * 
 * @export
 * @interface UploadPhotoFromVrResponse
 */
export interface UploadPhotoFromVrResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadPhotoFromVrResponse
     */
    'screenName': string;
    /**
     * 
     * @type {string}
     * @memberof UploadPhotoFromVrResponse
     */
    'sceneGuid': string;
}
/**
 * 
 * @export
 * @interface UploadProfileImageResponse
 */
export interface UploadProfileImageResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UploadProfileImageResponse
     */
    'ok': boolean;
    /**
     * 
     * @type {string}
     * @memberof UploadProfileImageResponse
     */
    'profile_thumbnail': string;
}
/**
 * 
 * @export
 * @interface UploadProfileSnsRequet
 */
export interface UploadProfileSnsRequet {
    /**
     * 
     * @type {string}
     * @memberof UploadProfileSnsRequet
     */
    'site_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadProfileSnsRequet
     */
    'twitter_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadProfileSnsRequet
     */
    'instagram_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadProfileSnsRequet
     */
    'facebook_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadProfileSnsRequet
     */
    'youtube_channel_url'?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'profile_image_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'facebook_id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'instagram_id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'twitter_id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'site_url': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'youtube_channel_url': string;
}
/**
 * 
 * @export
 * @interface UsersItem
 */
export interface UsersItem {
    /**
     * 
     * @type {string}
     * @memberof UsersItem
     */
    'bu_serial': string;
    /**
     * 
     * @type {string}
     * @memberof UsersItem
     */
    'bu_screen_name': string;
    /**
     * 
     * @type {string}
     * @memberof UsersItem
     */
    'profile_thumbnail': string;
}

/**
 * AssetConversionApi - axios parameter creator
 * @export
 */
export const AssetConversionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a new STYLY asset conversion record of the user
         * @param {CreateAssetConversionRequest} createAssetConversionRequest Payload with file informations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversion: async (createAssetConversionRequest: CreateAssetConversionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAssetConversionRequest' is not null or undefined
            assertParamExists('conversion', 'createAssetConversionRequest', createAssetConversionRequest)
            const localVarPath = `/asset_conversion/conversion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAssetConversionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Triggers conversion by model converter server.
         * @param {string} id Conversion ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        start: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('start', 'id', id)
            const localVarPath = `/asset_conversion/{id}/start`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates status of a conversion.
         * @param {UpdateStateAssetConversionRequest} updateStateAssetConversionRequest Payload with JobID and ConverterState.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        state: async (updateStateAssetConversionRequest: UpdateStateAssetConversionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateStateAssetConversionRequest' is not null or undefined
            assertParamExists('state', 'updateStateAssetConversionRequest', updateStateAssetConversionRequest)
            const localVarPath = `/asset_conversion/state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStateAssetConversionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetConversionApi - functional programming interface
 * @export
 */
export const AssetConversionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetConversionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a new STYLY asset conversion record of the user
         * @param {CreateAssetConversionRequest} createAssetConversionRequest Payload with file informations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversion(createAssetConversionRequest: CreateAssetConversionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversion(createAssetConversionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Triggers conversion by model converter server.
         * @param {string} id Conversion ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async start(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.start(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates status of a conversion.
         * @param {UpdateStateAssetConversionRequest} updateStateAssetConversionRequest Payload with JobID and ConverterState.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async state(updateStateAssetConversionRequest: UpdateStateAssetConversionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.state(updateStateAssetConversionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetConversionApi - factory interface
 * @export
 */
export const AssetConversionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetConversionApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a new STYLY asset conversion record of the user
         * @param {CreateAssetConversionRequest} createAssetConversionRequest Payload with file informations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversion(createAssetConversionRequest: CreateAssetConversionRequest, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.conversion(createAssetConversionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Triggers conversion by model converter server.
         * @param {string} id Conversion ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        start(id: string, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.start(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates status of a conversion.
         * @param {UpdateStateAssetConversionRequest} updateStateAssetConversionRequest Payload with JobID and ConverterState.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        state(updateStateAssetConversionRequest: UpdateStateAssetConversionRequest, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.state(updateStateAssetConversionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetConversionApi - object-oriented interface
 * @export
 * @class AssetConversionApi
 * @extends {BaseAPI}
 */
export class AssetConversionApi extends BaseAPI {
    /**
     * 
     * @summary Creates a new STYLY asset conversion record of the user
     * @param {CreateAssetConversionRequest} createAssetConversionRequest Payload with file informations.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetConversionApi
     */
    public conversion(createAssetConversionRequest: CreateAssetConversionRequest, options?: AxiosRequestConfig) {
        return AssetConversionApiFp(this.configuration).conversion(createAssetConversionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Triggers conversion by model converter server.
     * @param {string} id Conversion ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetConversionApi
     */
    public start(id: string, options?: AxiosRequestConfig) {
        return AssetConversionApiFp(this.configuration).start(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates status of a conversion.
     * @param {UpdateStateAssetConversionRequest} updateStateAssetConversionRequest Payload with JobID and ConverterState.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetConversionApi
     */
    public state(updateStateAssetConversionRequest: UpdateStateAssetConversionRequest, options?: AxiosRequestConfig) {
        return AssetConversionApiFp(this.configuration).state(updateStateAssetConversionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new login session with email and password
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginRequest: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('login', 'loginRequest', loginRequest)
            const localVarPath = `/_session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ログイン中ユーザーの詳細情報を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/_session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log out from the current session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/_session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アカウントを作成
         * @param {SignupRequest} signupRequest アカウントの登録内容
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup: async (signupRequest: SignupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signupRequest' is not null or undefined
            assertParamExists('signup', 'signupRequest', signupRequest)
            const localVarPath = `/_signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new login session with email and password
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginRequest: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ログイン中ユーザーの詳細情報を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Log out from the current session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary アカウントを作成
         * @param {SignupRequest} signupRequest アカウントの登録内容
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signup(signupRequest: SignupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signup(signupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new login session with email and password
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest: LoginRequest, options?: any): AxiosPromise<LoginUser> {
            return localVarFp.login(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ログイン中ユーザーの詳細情報を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser(options?: any): AxiosPromise<LoginUser> {
            return localVarFp.loginUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Log out from the current session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary アカウントを作成
         * @param {SignupRequest} signupRequest アカウントの登録内容
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(signupRequest: SignupRequest, options?: any): AxiosPromise<SignupResponse> {
            return localVarFp.signup(signupRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Create new login session with email and password
     * @param {LoginRequest} loginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(loginRequest: LoginRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).login(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ログイン中ユーザーの詳細情報を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginUser(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Log out from the current session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logout(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary アカウントを作成
     * @param {SignupRequest} signupRequest アカウントの登録内容
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public signup(signupRequest: SignupRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).signup(signupRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AzureApi - axios parameter creator
 * @export
 */
export const AzureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve the pre-signed url for uploading a file to Azure
         * @param {string} id styly asset guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedUrlForAsset: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSignedUrlForAsset', 'id', id)
            const localVarPath = `/azure/asset/{id}/signed-url`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a signed request URL for scene file
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedUrlForScene: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSignedUrlForScene', 'id', id)
            const localVarPath = `/azure/scene/{id}/signed-url`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a signed request URL for user upload file
         * @param {string} filename File name to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedUrlForUserfile: async (filename: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('getSignedUrlForUserfile', 'filename', filename)
            const localVarPath = `/azure/userfile/{filename}/signed-url`
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates all signed URLs for all supported platforms.
         * @param {GetSignedUrlsForUnityPluginRequest} getSignedUrlsForUnityPluginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedUrlsForUnityPlugin: async (getSignedUrlsForUnityPluginRequest: GetSignedUrlsForUnityPluginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getSignedUrlsForUnityPluginRequest' is not null or undefined
            assertParamExists('getSignedUrlsForUnityPlugin', 'getSignedUrlsForUnityPluginRequest', getSignedUrlsForUnityPluginRequest)
            const localVarPath = `/azure/unity_plugin/signed-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getSignedUrlsForUnityPluginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AzureApi - functional programming interface
 * @export
 */
export const AzureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AzureApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve the pre-signed url for uploading a file to Azure
         * @param {string} id styly asset guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSignedUrlForAsset(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSignedUrlForAsset200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSignedUrlForAsset(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a signed request URL for scene file
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSignedUrlForScene(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSignedUrlForScene200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSignedUrlForScene(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a signed request URL for user upload file
         * @param {string} filename File name to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSignedUrlForUserfile(filename: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSignedUrlForUserfile200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSignedUrlForUserfile(filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates all signed URLs for all supported platforms.
         * @param {GetSignedUrlsForUnityPluginRequest} getSignedUrlsForUnityPluginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSignedUrlsForUnityPlugin(getSignedUrlsForUnityPluginRequest: GetSignedUrlsForUnityPluginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSignedUrlsForUnityPluginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSignedUrlsForUnityPlugin(getSignedUrlsForUnityPluginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AzureApi - factory interface
 * @export
 */
export const AzureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AzureApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve the pre-signed url for uploading a file to Azure
         * @param {string} id styly asset guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedUrlForAsset(id: string, options?: any): AxiosPromise<GetSignedUrlForAsset200Response> {
            return localVarFp.getSignedUrlForAsset(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a signed request URL for scene file
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedUrlForScene(id: string, options?: any): AxiosPromise<GetSignedUrlForScene200Response> {
            return localVarFp.getSignedUrlForScene(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a signed request URL for user upload file
         * @param {string} filename File name to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedUrlForUserfile(filename: string, options?: any): AxiosPromise<GetSignedUrlForUserfile200Response> {
            return localVarFp.getSignedUrlForUserfile(filename, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates all signed URLs for all supported platforms.
         * @param {GetSignedUrlsForUnityPluginRequest} getSignedUrlsForUnityPluginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedUrlsForUnityPlugin(getSignedUrlsForUnityPluginRequest: GetSignedUrlsForUnityPluginRequest, options?: any): AxiosPromise<GetSignedUrlsForUnityPluginResponse> {
            return localVarFp.getSignedUrlsForUnityPlugin(getSignedUrlsForUnityPluginRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AzureApi - object-oriented interface
 * @export
 * @class AzureApi
 * @extends {BaseAPI}
 */
export class AzureApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve the pre-signed url for uploading a file to Azure
     * @param {string} id styly asset guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AzureApi
     */
    public getSignedUrlForAsset(id: string, options?: AxiosRequestConfig) {
        return AzureApiFp(this.configuration).getSignedUrlForAsset(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a signed request URL for scene file
     * @param {string} id Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AzureApi
     */
    public getSignedUrlForScene(id: string, options?: AxiosRequestConfig) {
        return AzureApiFp(this.configuration).getSignedUrlForScene(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a signed request URL for user upload file
     * @param {string} filename File name to upload.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AzureApi
     */
    public getSignedUrlForUserfile(filename: string, options?: AxiosRequestConfig) {
        return AzureApiFp(this.configuration).getSignedUrlForUserfile(filename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates all signed URLs for all supported platforms.
     * @param {GetSignedUrlsForUnityPluginRequest} getSignedUrlsForUnityPluginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AzureApi
     */
    public getSignedUrlsForUnityPlugin(getSignedUrlsForUnityPluginRequest: GetSignedUrlsForUnityPluginRequest, options?: AxiosRequestConfig) {
        return AzureApiFp(this.configuration).getSignedUrlsForUnityPlugin(getSignedUrlsForUnityPluginRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocationApi - axios parameter creator
 * @export
 */
export const LocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ロケーションの削除
         * @param {string} id ロケーションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLocation', 'id', id)
            const localVarPath = `/location/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ロケーションの取得
         * @param {string} id ロケーションID
         * @param {number} [full] 1を指定すると、シーンデータ(scenes)も含まれる
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocation: async (id: string, full?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLocation', 'id', id)
            const localVarPath = `/location/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (full !== undefined) {
                localVarQueryParameter['full'] = full;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ロケーション一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loctions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/location/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ロケーションの新規作成
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newLocation: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/location/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ロケーションの更新
         * @param {string} id ロケーションID
         * @param {UpdateLocationRequest} [updateLocationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation: async (id: string, updateLocationRequest?: UpdateLocationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLocation', 'id', id)
            const localVarPath = `/location/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLocationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationApi - functional programming interface
 * @export
 */
export const LocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ロケーションの削除
         * @param {string} id ロケーションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLocation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLocation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ロケーションの取得
         * @param {string} id ロケーションID
         * @param {number} [full] 1を指定すると、シーンデータ(scenes)も含まれる
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocation(id: string, full?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Location>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocation(id, full, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ロケーション一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loctions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Location>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loctions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ロケーションの新規作成
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newLocation(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Location>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newLocation(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ロケーションの更新
         * @param {string} id ロケーションID
         * @param {UpdateLocationRequest} [updateLocationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLocation(id: string, updateLocationRequest?: UpdateLocationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Location>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLocation(id, updateLocationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocationApi - factory interface
 * @export
 */
export const LocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationApiFp(configuration)
    return {
        /**
         * 
         * @summary ロケーションの削除
         * @param {string} id ロケーションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocation(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLocation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ロケーションの取得
         * @param {string} id ロケーションID
         * @param {number} [full] 1を指定すると、シーンデータ(scenes)も含まれる
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocation(id: string, full?: number, options?: any): AxiosPromise<Location> {
            return localVarFp.getLocation(id, full, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ロケーション一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loctions(options?: any): AxiosPromise<Array<Location>> {
            return localVarFp.loctions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ロケーションの新規作成
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newLocation(options?: any): AxiosPromise<Location> {
            return localVarFp.newLocation(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ロケーションの更新
         * @param {string} id ロケーションID
         * @param {UpdateLocationRequest} [updateLocationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation(id: string, updateLocationRequest?: UpdateLocationRequest, options?: any): AxiosPromise<Location> {
            return localVarFp.updateLocation(id, updateLocationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationApi - object-oriented interface
 * @export
 * @class LocationApi
 * @extends {BaseAPI}
 */
export class LocationApi extends BaseAPI {
    /**
     * 
     * @summary ロケーションの削除
     * @param {string} id ロケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public deleteLocation(id: string, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).deleteLocation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ロケーションの取得
     * @param {string} id ロケーションID
     * @param {number} [full] 1を指定すると、シーンデータ(scenes)も含まれる
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public getLocation(id: string, full?: number, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).getLocation(id, full, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ロケーション一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public loctions(options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).loctions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ロケーションの新規作成
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public newLocation(options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).newLocation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ロケーションの更新
     * @param {string} id ロケーションID
     * @param {UpdateLocationRequest} [updateLocationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public updateLocation(id: string, updateLocationRequest?: UpdateLocationRequest, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).updateLocation(id, updateLocationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MultiplayerApi - axios parameter creator
 * @export
 */
export const MultiplayerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary セッション登録
         * @param {CreateSessionRequest} [createSessionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSession: async (createSessionRequest?: CreateSessionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/multiplayer/session/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSessionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary セッションの削除
         * @param {string} id Session ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSession: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSession', 'id', id)
            const localVarPath = `/multiplayer/session/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary セッションへのログアウト機能
         * @param {string} id Session ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exitSession: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('exitSession', 'id', id)
            const localVarPath = `/multiplayer/session/{id}/exit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 自分が作ったセッションの表示
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMySessions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/multiplayer/session/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary グローバルIPからセッション表示
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicSessionsByGlobalIp: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/multiplayer/session/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ディスプレイIDによるセッション表示
         * @param {string} displayId ディスプレイID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionByDisplayId: async (displayId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'displayId' is not null or undefined
            assertParamExists('getSessionByDisplayId', 'displayId', displayId)
            const localVarPath = `/multiplayer/session/display/{display_id}`
                .replace(`{${"display_id"}}`, encodeURIComponent(String(displayId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary セッションへのログイン機能
         * @param {string} id Session ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinSession: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('joinSession', 'id', id)
            const localVarPath = `/multiplayer/session/{id}/join`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary セッションの更新
         * @param {string} id Session ID
         * @param {UpdateSessionRequest} [updateSessionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSession: async (id: string, updateSessionRequest?: UpdateSessionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSession', 'id', id)
            const localVarPath = `/multiplayer/session/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSessionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MultiplayerApi - functional programming interface
 * @export
 */
export const MultiplayerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MultiplayerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary セッション登録
         * @param {CreateSessionRequest} [createSessionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSession(createSessionRequest?: CreateSessionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Session>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSession(createSessionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary セッションの削除
         * @param {string} id Session ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSession(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSession(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary セッションへのログアウト機能
         * @param {string} id Session ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exitSession(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExitSessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exitSession(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 自分が作ったセッションの表示
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMySessions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMySessions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary グローバルIPからセッション表示
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicSessionsByGlobalIp(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicSessionsByGlobalIp(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ディスプレイIDによるセッション表示
         * @param {string} displayId ディスプレイID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSessionByDisplayId(displayId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Session>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSessionByDisplayId(displayId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary セッションへのログイン機能
         * @param {string} id Session ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async joinSession(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JoinSessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.joinSession(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary セッションの更新
         * @param {string} id Session ID
         * @param {UpdateSessionRequest} [updateSessionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSession(id: string, updateSessionRequest?: UpdateSessionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Session>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSession(id, updateSessionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MultiplayerApi - factory interface
 * @export
 */
export const MultiplayerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MultiplayerApiFp(configuration)
    return {
        /**
         * 
         * @summary セッション登録
         * @param {CreateSessionRequest} [createSessionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSession(createSessionRequest?: CreateSessionRequest, options?: any): AxiosPromise<Session> {
            return localVarFp.createSession(createSessionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary セッションの削除
         * @param {string} id Session ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSession(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSession(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary セッションへのログアウト機能
         * @param {string} id Session ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exitSession(id: string, options?: any): AxiosPromise<ExitSessionResponse> {
            return localVarFp.exitSession(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 自分が作ったセッションの表示
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMySessions(options?: any): AxiosPromise<SessionList> {
            return localVarFp.getMySessions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary グローバルIPからセッション表示
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicSessionsByGlobalIp(options?: any): AxiosPromise<SessionList> {
            return localVarFp.getPublicSessionsByGlobalIp(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ディスプレイIDによるセッション表示
         * @param {string} displayId ディスプレイID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionByDisplayId(displayId: string, options?: any): AxiosPromise<Session> {
            return localVarFp.getSessionByDisplayId(displayId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary セッションへのログイン機能
         * @param {string} id Session ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinSession(id: string, options?: any): AxiosPromise<JoinSessionResponse> {
            return localVarFp.joinSession(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary セッションの更新
         * @param {string} id Session ID
         * @param {UpdateSessionRequest} [updateSessionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSession(id: string, updateSessionRequest?: UpdateSessionRequest, options?: any): AxiosPromise<Session> {
            return localVarFp.updateSession(id, updateSessionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MultiplayerApi - object-oriented interface
 * @export
 * @class MultiplayerApi
 * @extends {BaseAPI}
 */
export class MultiplayerApi extends BaseAPI {
    /**
     * 
     * @summary セッション登録
     * @param {CreateSessionRequest} [createSessionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultiplayerApi
     */
    public createSession(createSessionRequest?: CreateSessionRequest, options?: AxiosRequestConfig) {
        return MultiplayerApiFp(this.configuration).createSession(createSessionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary セッションの削除
     * @param {string} id Session ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultiplayerApi
     */
    public deleteSession(id: string, options?: AxiosRequestConfig) {
        return MultiplayerApiFp(this.configuration).deleteSession(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary セッションへのログアウト機能
     * @param {string} id Session ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultiplayerApi
     */
    public exitSession(id: string, options?: AxiosRequestConfig) {
        return MultiplayerApiFp(this.configuration).exitSession(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 自分が作ったセッションの表示
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultiplayerApi
     */
    public getMySessions(options?: AxiosRequestConfig) {
        return MultiplayerApiFp(this.configuration).getMySessions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary グローバルIPからセッション表示
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultiplayerApi
     */
    public getPublicSessionsByGlobalIp(options?: AxiosRequestConfig) {
        return MultiplayerApiFp(this.configuration).getPublicSessionsByGlobalIp(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ディスプレイIDによるセッション表示
     * @param {string} displayId ディスプレイID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultiplayerApi
     */
    public getSessionByDisplayId(displayId: string, options?: AxiosRequestConfig) {
        return MultiplayerApiFp(this.configuration).getSessionByDisplayId(displayId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary セッションへのログイン機能
     * @param {string} id Session ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultiplayerApi
     */
    public joinSession(id: string, options?: AxiosRequestConfig) {
        return MultiplayerApiFp(this.configuration).joinSession(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary セッションの更新
     * @param {string} id Session ID
     * @param {UpdateSessionRequest} [updateSessionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultiplayerApi
     */
    public updateSession(id: string, updateSessionRequest?: UpdateSessionRequest, options?: AxiosRequestConfig) {
        return MultiplayerApiFp(this.configuration).updateSession(id, updateSessionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PhotoApi - axios parameter creator
 * @export
 */
export const PhotoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary シーンのサムネイルをアップロードする
         * @param {string} scGuid 
         * @param {any} photo 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPhotoFromVr: async (scGuid: string, photo: any, comment?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scGuid' is not null or undefined
            assertParamExists('uploadPhotoFromVr', 'scGuid', scGuid)
            // verify required parameter 'photo' is not null or undefined
            assertParamExists('uploadPhotoFromVr', 'photo', photo)
            const localVarPath = `/photos/vr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (scGuid !== undefined) { 
                localVarFormParams.append('sc_guid', scGuid as any);
            }
    
            if (comment !== undefined) { 
                localVarFormParams.append('comment', comment as any);
            }
    
            if (photo !== undefined) { 
                localVarFormParams.append('photo', photo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary シーンのサムネイルをアップロードする
         * @param {string} scGuid 
         * @param {any} photo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPhotoFromWeb: async (scGuid: string, photo: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scGuid' is not null or undefined
            assertParamExists('uploadPhotoFromWeb', 'scGuid', scGuid)
            // verify required parameter 'photo' is not null or undefined
            assertParamExists('uploadPhotoFromWeb', 'photo', photo)
            const localVarPath = `/photos/web`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (scGuid !== undefined) { 
                localVarFormParams.append('sc_guid', scGuid as any);
            }
    
            if (photo !== undefined) { 
                localVarFormParams.append('photo', photo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PhotoApi - functional programming interface
 * @export
 */
export const PhotoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PhotoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary シーンのサムネイルをアップロードする
         * @param {string} scGuid 
         * @param {any} photo 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPhotoFromVr(scGuid: string, photo: any, comment?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadPhotoFromVrResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPhotoFromVr(scGuid, photo, comment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary シーンのサムネイルをアップロードする
         * @param {string} scGuid 
         * @param {any} photo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPhotoFromWeb(scGuid: string, photo: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPhotoFromWeb(scGuid, photo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PhotoApi - factory interface
 * @export
 */
export const PhotoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PhotoApiFp(configuration)
    return {
        /**
         * 
         * @summary シーンのサムネイルをアップロードする
         * @param {string} scGuid 
         * @param {any} photo 
         * @param {string} [comment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPhotoFromVr(scGuid: string, photo: any, comment?: string, options?: any): AxiosPromise<UploadPhotoFromVrResponse> {
            return localVarFp.uploadPhotoFromVr(scGuid, photo, comment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary シーンのサムネイルをアップロードする
         * @param {string} scGuid 
         * @param {any} photo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPhotoFromWeb(scGuid: string, photo: any, options?: any): AxiosPromise<boolean> {
            return localVarFp.uploadPhotoFromWeb(scGuid, photo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PhotoApi - object-oriented interface
 * @export
 * @class PhotoApi
 * @extends {BaseAPI}
 */
export class PhotoApi extends BaseAPI {
    /**
     * 
     * @summary シーンのサムネイルをアップロードする
     * @param {string} scGuid 
     * @param {any} photo 
     * @param {string} [comment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoApi
     */
    public uploadPhotoFromVr(scGuid: string, photo: any, comment?: string, options?: AxiosRequestConfig) {
        return PhotoApiFp(this.configuration).uploadPhotoFromVr(scGuid, photo, comment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary シーンのサムネイルをアップロードする
     * @param {string} scGuid 
     * @param {any} photo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotoApi
     */
    public uploadPhotoFromWeb(scGuid: string, photo: any, options?: AxiosRequestConfig) {
        return PhotoApiFp(this.configuration).uploadPhotoFromWeb(scGuid, photo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SceneApi - axios parameter creator
 * @export
 */
export const SceneApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add good api.
         * @param {string} scGuid Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGood: async (scGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scGuid' is not null or undefined
            assertParamExists('addGood', 'scGuid', scGuid)
            const localVarPath = `/scene/{sc_guid}/good`
                .replace(`{${"sc_guid"}}`, encodeURIComponent(String(scGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add MyList
         * @param {string} scGuid Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMylist: async (scGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scGuid' is not null or undefined
            assertParamExists('addMylist', 'scGuid', scGuid)
            const localVarPath = `/scene/mylist/{sc_guid}`
                .replace(`{${"sc_guid"}}`, encodeURIComponent(String(scGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User can copy thier scenes. Cannot copy scenes of other users.
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copySceneAsLogginUser: async (sceneId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneId' is not null or undefined
            assertParamExists('copySceneAsLogginUser', 'sceneId', sceneId)
            const localVarPath = `/scene/{scene_id}/copy`
                .replace(`{${"scene_id"}}`, encodeURIComponent(String(sceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the scene of the brand user
         * @param {string} scGuid Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScene: async (scGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scGuid' is not null or undefined
            assertParamExists('deleteScene', 'scGuid', scGuid)
            const localVarPath = `/scene/{sc_guid}`
                .replace(`{${"sc_guid"}}`, encodeURIComponent(String(scGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary コメントを削除
         * @param {string} scGuid Scene ID
         * @param {number} smSerial scene_comment_table.sm_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSceneComment: async (scGuid: string, smSerial: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scGuid' is not null or undefined
            assertParamExists('deleteSceneComment', 'scGuid', scGuid)
            // verify required parameter 'smSerial' is not null or undefined
            assertParamExists('deleteSceneComment', 'smSerial', smSerial)
            const localVarPath = `/scene/{sc_guid}/comment/{sm_serial}`
                .replace(`{${"sc_guid"}}`, encodeURIComponent(String(scGuid)))
                .replace(`{${"sm_serial"}}`, encodeURIComponent(String(smSerial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a scene by GUID.
         * @param {string} scGuid Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScene: async (scGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scGuid' is not null or undefined
            assertParamExists('getScene', 'scGuid', scGuid)
            const localVarPath = `/scene/{sc_guid}`
                .replace(`{${"sc_guid"}}`, encodeURIComponent(String(scGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary コメントを取得
         * @param {string} scGuid Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSceneComments: async (scGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scGuid' is not null or undefined
            assertParamExists('getSceneComments', 'scGuid', scGuid)
            const localVarPath = `/scene/{sc_guid}/comment`
                .replace(`{${"sc_guid"}}`, encodeURIComponent(String(scGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary シーンビュー数のカウントアップ
         * @param {string} scGuid Scene ID
         * @param {number} [platformType] プラットフォーム種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incrementSceneView: async (scGuid: string, platformType?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scGuid' is not null or undefined
            assertParamExists('incrementSceneView', 'scGuid', scGuid)
            const localVarPath = `/scene/{sc_guid}/increment_view`
                .replace(`{${"sc_guid"}}`, encodeURIComponent(String(scGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (platformType !== undefined) {
                localVarQueryParameter['platform_type'] = platformType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary コメントを登録
         * @param {string} scGuid Scene ID
         * @param {string} comment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSceneComment: async (scGuid: string, comment: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scGuid' is not null or undefined
            assertParamExists('postSceneComment', 'scGuid', scGuid)
            // verify required parameter 'comment' is not null or undefined
            assertParamExists('postSceneComment', 'comment', comment)
            const localVarPath = `/scene/{sc_guid}/comment`
                .replace(`{${"sc_guid"}}`, encodeURIComponent(String(scGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (comment !== undefined) { 
                localVarFormParams.append('comment', comment as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete good api.
         * @param {string} scGuid Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeGood: async (scGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scGuid' is not null or undefined
            assertParamExists('removeGood', 'scGuid', scGuid)
            const localVarPath = `/scene/{sc_guid}/good`
                .replace(`{${"sc_guid"}}`, encodeURIComponent(String(scGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add MyList
         * @param {string} scGuid Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMylist: async (scGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scGuid' is not null or undefined
            assertParamExists('removeMylist', 'scGuid', scGuid)
            const localVarPath = `/scene/mylist/{sc_guid}`
                .replace(`{${"sc_guid"}}`, encodeURIComponent(String(scGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save the scene of the brand user
         * @param {SaveSceneRequest} saveSceneRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveScene: async (saveSceneRequest: SaveSceneRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveSceneRequest' is not null or undefined
            assertParamExists('saveScene', 'saveSceneRequest', saveSceneRequest)
            const localVarPath = `/scene`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveSceneRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve scene list of the public
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {string} [sort] 
         * @param {boolean} [desc] 
         * @param {number} [minRating] 
         * @param {boolean} [noXml] 
         * @param {number} [scCategorySerial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenes: async (limit?: number, skip?: number, sort?: string, desc?: boolean, minRating?: number, noXml?: boolean, scCategorySerial?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scene`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (desc !== undefined) {
                localVarQueryParameter['desc'] = desc;
            }

            if (minRating !== undefined) {
                localVarQueryParameter['min_rating'] = minRating;
            }

            if (noXml !== undefined) {
                localVarQueryParameter['no_xml'] = noXml;
            }

            if (scCategorySerial !== undefined) {
                localVarQueryParameter['sc_category_serial'] = scCategorySerial;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates styly_asset record for Unity Plugin.
         * @param {UnityPluginCompleteRequest} unityPluginCompleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unityPluginComplete: async (unityPluginCompleteRequest: UnityPluginCompleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unityPluginCompleteRequest' is not null or undefined
            assertParamExists('unityPluginComplete', 'unityPluginCompleteRequest', unityPluginCompleteRequest)
            const localVarPath = `/scene/unity_plugin/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unityPluginCompleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SceneApi - functional programming interface
 * @export
 */
export const SceneApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SceneApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary add good api.
         * @param {string} scGuid Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addGood(scGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetScene>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addGood(scGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add MyList
         * @param {string} scGuid Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMylist(scGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddMylistResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMylist(scGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User can copy thier scenes. Cannot copy scenes of other users.
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copySceneAsLogginUser(sceneId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CopySceneAsLogginUser200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copySceneAsLogginUser(sceneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the scene of the brand user
         * @param {string} scGuid Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScene(scGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScene(scGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary コメントを削除
         * @param {string} scGuid Scene ID
         * @param {number} smSerial scene_comment_table.sm_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSceneComment(scGuid: string, smSerial: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetScene>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSceneComment(scGuid, smSerial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a scene by GUID.
         * @param {string} scGuid Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScene(scGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSceneResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScene(scGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary コメントを取得
         * @param {string} scGuid Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSceneComments(scGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SceneComment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSceneComments(scGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary シーンビュー数のカウントアップ
         * @param {string} scGuid Scene ID
         * @param {number} [platformType] プラットフォーム種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async incrementSceneView(scGuid: string, platformType?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.incrementSceneView(scGuid, platformType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary コメントを登録
         * @param {string} scGuid Scene ID
         * @param {string} comment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSceneComment(scGuid: string, comment: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetScene>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSceneComment(scGuid, comment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete good api.
         * @param {string} scGuid Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeGood(scGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetScene>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeGood(scGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add MyList
         * @param {string} scGuid Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeMylist(scGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeMylist(scGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save the scene of the brand user
         * @param {SaveSceneRequest} saveSceneRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveScene(saveSceneRequest: SaveSceneRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveSceneResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveScene(saveSceneRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve scene list of the public
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {string} [sort] 
         * @param {boolean} [desc] 
         * @param {number} [minRating] 
         * @param {boolean} [noXml] 
         * @param {number} [scCategorySerial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenes(limit?: number, skip?: number, sort?: string, desc?: boolean, minRating?: number, noXml?: boolean, scCategorySerial?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetScenes>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scenes(limit, skip, sort, desc, minRating, noXml, scCategorySerial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates styly_asset record for Unity Plugin.
         * @param {UnityPluginCompleteRequest} unityPluginCompleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unityPluginComplete(unityPluginCompleteRequest: UnityPluginCompleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unityPluginComplete(unityPluginCompleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SceneApi - factory interface
 * @export
 */
export const SceneApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SceneApiFp(configuration)
    return {
        /**
         * 
         * @summary add good api.
         * @param {string} scGuid Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGood(scGuid: string, options?: any): AxiosPromise<GetScene> {
            return localVarFp.addGood(scGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add MyList
         * @param {string} scGuid Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMylist(scGuid: string, options?: any): AxiosPromise<AddMylistResponse> {
            return localVarFp.addMylist(scGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User can copy thier scenes. Cannot copy scenes of other users.
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copySceneAsLogginUser(sceneId: string, options?: any): AxiosPromise<CopySceneAsLogginUser200Response> {
            return localVarFp.copySceneAsLogginUser(sceneId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the scene of the brand user
         * @param {string} scGuid Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScene(scGuid: string, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.deleteScene(scGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary コメントを削除
         * @param {string} scGuid Scene ID
         * @param {number} smSerial scene_comment_table.sm_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSceneComment(scGuid: string, smSerial: number, options?: any): AxiosPromise<GetScene> {
            return localVarFp.deleteSceneComment(scGuid, smSerial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a scene by GUID.
         * @param {string} scGuid Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScene(scGuid: string, options?: any): AxiosPromise<GetSceneResponse> {
            return localVarFp.getScene(scGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary コメントを取得
         * @param {string} scGuid Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSceneComments(scGuid: string, options?: any): AxiosPromise<Array<SceneComment>> {
            return localVarFp.getSceneComments(scGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary シーンビュー数のカウントアップ
         * @param {string} scGuid Scene ID
         * @param {number} [platformType] プラットフォーム種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incrementSceneView(scGuid: string, platformType?: number, options?: any): AxiosPromise<void> {
            return localVarFp.incrementSceneView(scGuid, platformType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary コメントを登録
         * @param {string} scGuid Scene ID
         * @param {string} comment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSceneComment(scGuid: string, comment: string, options?: any): AxiosPromise<GetScene> {
            return localVarFp.postSceneComment(scGuid, comment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete good api.
         * @param {string} scGuid Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeGood(scGuid: string, options?: any): AxiosPromise<GetScene> {
            return localVarFp.removeGood(scGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add MyList
         * @param {string} scGuid Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMylist(scGuid: string, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.removeMylist(scGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save the scene of the brand user
         * @param {SaveSceneRequest} saveSceneRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveScene(saveSceneRequest: SaveSceneRequest, options?: any): AxiosPromise<SaveSceneResponse> {
            return localVarFp.saveScene(saveSceneRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve scene list of the public
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {string} [sort] 
         * @param {boolean} [desc] 
         * @param {number} [minRating] 
         * @param {boolean} [noXml] 
         * @param {number} [scCategorySerial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenes(limit?: number, skip?: number, sort?: string, desc?: boolean, minRating?: number, noXml?: boolean, scCategorySerial?: number, options?: any): AxiosPromise<Array<GetScenes>> {
            return localVarFp.scenes(limit, skip, sort, desc, minRating, noXml, scCategorySerial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates styly_asset record for Unity Plugin.
         * @param {UnityPluginCompleteRequest} unityPluginCompleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unityPluginComplete(unityPluginCompleteRequest: UnityPluginCompleteRequest, options?: any): AxiosPromise<object> {
            return localVarFp.unityPluginComplete(unityPluginCompleteRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SceneApi - object-oriented interface
 * @export
 * @class SceneApi
 * @extends {BaseAPI}
 */
export class SceneApi extends BaseAPI {
    /**
     * 
     * @summary add good api.
     * @param {string} scGuid Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public addGood(scGuid: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).addGood(scGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add MyList
     * @param {string} scGuid Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public addMylist(scGuid: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).addMylist(scGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User can copy thier scenes. Cannot copy scenes of other users.
     * @param {string} sceneId Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public copySceneAsLogginUser(sceneId: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).copySceneAsLogginUser(sceneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the scene of the brand user
     * @param {string} scGuid Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public deleteScene(scGuid: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).deleteScene(scGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary コメントを削除
     * @param {string} scGuid Scene ID
     * @param {number} smSerial scene_comment_table.sm_serial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public deleteSceneComment(scGuid: string, smSerial: number, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).deleteSceneComment(scGuid, smSerial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a scene by GUID.
     * @param {string} scGuid Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getScene(scGuid: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).getScene(scGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary コメントを取得
     * @param {string} scGuid Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getSceneComments(scGuid: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).getSceneComments(scGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary シーンビュー数のカウントアップ
     * @param {string} scGuid Scene ID
     * @param {number} [platformType] プラットフォーム種別
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public incrementSceneView(scGuid: string, platformType?: number, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).incrementSceneView(scGuid, platformType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary コメントを登録
     * @param {string} scGuid Scene ID
     * @param {string} comment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public postSceneComment(scGuid: string, comment: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).postSceneComment(scGuid, comment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete good api.
     * @param {string} scGuid Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public removeGood(scGuid: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).removeGood(scGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add MyList
     * @param {string} scGuid Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public removeMylist(scGuid: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).removeMylist(scGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save the scene of the brand user
     * @param {SaveSceneRequest} saveSceneRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public saveScene(saveSceneRequest: SaveSceneRequest, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).saveScene(saveSceneRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve scene list of the public
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {string} [sort] 
     * @param {boolean} [desc] 
     * @param {number} [minRating] 
     * @param {boolean} [noXml] 
     * @param {number} [scCategorySerial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public scenes(limit?: number, skip?: number, sort?: string, desc?: boolean, minRating?: number, noXml?: boolean, scCategorySerial?: number, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).scenes(limit, skip, sort, desc, minRating, noXml, scCategorySerial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates styly_asset record for Unity Plugin.
     * @param {UnityPluginCompleteRequest} unityPluginCompleteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public unityPluginComplete(unityPluginCompleteRequest: UnityPluginCompleteRequest, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).unityPluginComplete(unityPluginCompleteRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add creator to follow-list
         * @param {number} creatorFollowSerial creator_follow_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFollow: async (creatorFollowSerial: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creatorFollowSerial' is not null or undefined
            assertParamExists('addFollow', 'creatorFollowSerial', creatorFollowSerial)
            const localVarPath = `/brand_user/follow/{creator_follow_serial}`
                .replace(`{${"creator_follow_serial"}}`, encodeURIComponent(String(creatorFollowSerial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary シーンをコピーする
         * @param {string} sceneId sc_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyScene: async (sceneId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneId' is not null or undefined
            assertParamExists('copyScene', 'sceneId', sceneId)
            const localVarPath = `/brand_user/scene/{sceneId}/copy`
                .replace(`{${"sceneId"}}`, encodeURIComponent(String(sceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register new styly asset conversion of the user
         * @param {string} serial bu_serial
         * @param {CreateConversionAssetRequest} createConversionAssetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConversionAsset: async (serial: string, createConversionAssetRequest: CreateConversionAssetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('createConversionAsset', 'serial', serial)
            // verify required parameter 'createConversionAssetRequest' is not null or undefined
            assertParamExists('createConversionAsset', 'createConversionAssetRequest', createConversionAssetRequest)
            const localVarPath = `/brand_user/{serial}/conversion`
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createConversionAssetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete specific styly asset of the user (logically)
         * @param {string} serial bu_serial
         * @param {string} guid asset guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAsset: async (serial: string, guid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('deleteAsset', 'serial', serial)
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('deleteAsset', 'guid', guid)
            const localVarPath = `/brand_user/{serial}/asset/{guid}`
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)))
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve styly assets of the user
         * @param {string} serial bu_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssets: async (serial: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('getAssets', 'serial', serial)
            const localVarPath = `/brand_user/{serial}/asset`
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets how many times changing access level is allowed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableRestrictionOperations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brand_user/available_restriction_operations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve styly asset conversion list of the user
         * @param {string} serial bu_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversionAssets: async (serial: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('getConversionAssets', 'serial', serial)
            const localVarPath = `/brand_user/{serial}/conversion`
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get create scenes list by follow user
         * @param {number} limit 
         * @param {number} skip 
         * @param {string} [sort] 
         * @param {boolean} [desc] 
         * @param {number} [minRating] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreateList: async (limit: number, skip: number, sort?: string, desc?: boolean, minRating?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getCreateList', 'limit', limit)
            // verify required parameter 'skip' is not null or undefined
            assertParamExists('getCreateList', 'skip', skip)
            const localVarPath = `/brand_user/createlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (desc !== undefined) {
                localVarQueryParameter['desc'] = desc;
            }

            if (minRating !== undefined) {
                localVarQueryParameter['min_rating'] = minRating;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary creatorの詳細情報とそのユーザが公開しているscenesを取得する
         * @param {string} screenName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreatorScene: async (screenName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'screenName' is not null or undefined
            assertParamExists('getCreatorScene', 'screenName', screenName)
            const localVarPath = `/creator/{screen_name}/scene`
                .replace(`{${"screen_name"}}`, encodeURIComponent(String(screenName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Follow List
         * @param {string} [skip] 
         * @param {string} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowList: async (skip?: string, limit?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brand_user/followlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary マイリストの一覧を取得する
         * @param {string} [scGuid] シーンIDでの絞り込み
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMylist: async (scGuid?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brand_user/mylist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (scGuid !== undefined) {
                localVarQueryParameter['sc_guid'] = scGuid;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gallary notification list.
         * @param {number} serial bu_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (serial: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('getNotifications', 'serial', serial)
            const localVarPath = `/brand_user/{serial}/notification`
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This api get user scene detail by sc_guid.
         * @param {string} serial bu_serial
         * @param {string} scGuid sc_guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSceneBySceneId: async (serial: string, scGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('getSceneBySceneId', 'serial', serial)
            // verify required parameter 'scGuid' is not null or undefined
            assertParamExists('getSceneBySceneId', 'scGuid', scGuid)
            const localVarPath = `/brand_user/{serial}/scene/{sc_guid}`
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)))
                .replace(`{${"sc_guid"}}`, encodeURIComponent(String(scGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This api get user scene detail by sc_serial.
         * @param {string} scSerial sc_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSceneBySceneSerial: async (scSerial: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scSerial' is not null or undefined
            assertParamExists('getSceneBySceneSerial', 'scSerial', scSerial)
            const localVarPath = `/brand_user/scene/{sc_serial}`
                .replace(`{${"sc_serial"}}`, encodeURIComponent(String(scSerial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve scene list of the brand user even with unlisted scenes for logged in user
         * @param {string} serial bu_serial
         * @param {boolean} [noXml] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenes: async (serial: string, noXml?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('getScenes', 'serial', serial)
            const localVarPath = `/brand_user/{serial}/scene`
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (noXml !== undefined) {
                localVarQueryParameter['no_xml'] = noXml;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー固有のファイルを取得する
         * @param {string} userId userId
         * @param {string} type image, music, pdf, video
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadFile: async (userId: string, type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUploadFile', 'userId', userId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getUploadFile', 'type', type)
            const localVarPath = `/brand_user/{userId}/uploads/{type}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets users by usernames.
         * @param {Array<string>} usernames カンマ区切りのusername
         * @param {number} [onlyBusiness] integer 1 (TRUE) or 0 (FALSE)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByUsernames: async (usernames: Array<string>, onlyBusiness?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usernames' is not null or undefined
            assertParamExists('getUsersByUsernames', 'usernames', usernames)
            const localVarPath = `/brand_user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (usernames) {
                localVarQueryParameter['usernames'] = usernames.join(COLLECTION_FORMATS.csv);
            }

            if (onlyBusiness !== undefined) {
                localVarQueryParameter['onlyBusiness'] = onlyBusiness;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check Followed user
         * @param {number} creatorFollowSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isFollowed: async (creatorFollowSerial: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creatorFollowSerial' is not null or undefined
            assertParamExists('isFollowed', 'creatorFollowSerial', creatorFollowSerial)
            const localVarPath = `/brand_user/follow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (creatorFollowSerial !== undefined) {
                localVarQueryParameter['creator_follow_serial'] = creatorFollowSerial;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete Follow List
         * @param {number} creatorFollowSerial creator_follow_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFollow: async (creatorFollowSerial: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creatorFollowSerial' is not null or undefined
            assertParamExists('removeFollow', 'creatorFollowSerial', creatorFollowSerial)
            const localVarPath = `/brand_user/follow/{creator_follow_serial}`
                .replace(`{${"creator_follow_serial"}}`, encodeURIComponent(String(creatorFollowSerial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary シーンのサムネイル画像を更新する
         * @param {number} scSerial sc_serial
         * @param {any} scThumbnailPhoto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSceneThumbnail: async (scSerial: number, scThumbnailPhoto: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scSerial' is not null or undefined
            assertParamExists('updateSceneThumbnail', 'scSerial', scSerial)
            // verify required parameter 'scThumbnailPhoto' is not null or undefined
            assertParamExists('updateSceneThumbnail', 'scThumbnailPhoto', scThumbnailPhoto)
            const localVarPath = `/brand_user/scene/{sc_serial}/thumbnail_photo`
                .replace(`{${"sc_serial"}}`, encodeURIComponent(String(scSerial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (scThumbnailPhoto !== undefined) { 
                localVarFormParams.append('sc_thumbnail_photo', scThumbnailPhoto as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ユーザー固有のファイルをアップロードする
         * @param {string} userId userId
         * @param {string} type image, music, pdf, video
         * @param {string} [filename] 
         * @param {string} [blobname] Upload先(Azure)と紐づく文字列
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (userId: string, type: string, filename?: string, blobname?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('uploadFile', 'userId', userId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('uploadFile', 'type', type)
            const localVarPath = `/brand_user/{userId}/uploads/{type}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (filename !== undefined) { 
                localVarFormParams.append('filename', filename as any);
            }
    
            if (blobname !== undefined) { 
                localVarFormParams.append('blobname', blobname as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload profile image
         * @param {any} [profile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfileImage: async (profile?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (profile !== undefined) { 
                localVarFormParams.append('profile', profile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload user sns profile
         * @param {UploadProfileSnsRequet} [uploadProfileSnsRequet] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfileSns: async (uploadProfileSnsRequet?: UploadProfileSnsRequet, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/sns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadProfileSnsRequet, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 通知を既読にする
         * @param {number} serial bu_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        watchNotifications: async (serial: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('watchNotifications', 'serial', serial)
            const localVarPath = `/brand_user/{serial}/notification/watch`
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary add creator to follow-list
         * @param {number} creatorFollowSerial creator_follow_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFollow(creatorFollowSerial: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddFollowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFollow(creatorFollowSerial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary シーンをコピーする
         * @param {string} sceneId sc_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyScene(sceneId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scene>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyScene(sceneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register new styly asset conversion of the user
         * @param {string} serial bu_serial
         * @param {CreateConversionAssetRequest} createConversionAssetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConversionAsset(serial: string, createConversionAssetRequest: CreateConversionAssetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConversionAsset(serial, createConversionAssetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete specific styly asset of the user (logically)
         * @param {string} serial bu_serial
         * @param {string} guid asset guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAsset(serial: string, guid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAsset(serial, guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve styly assets of the user
         * @param {string} serial bu_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssets(serial: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Asset>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssets(serial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets how many times changing access level is allowed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableRestrictionOperations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestrictionOperationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableRestrictionOperations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve styly asset conversion list of the user
         * @param {string} serial bu_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversionAssets(serial: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConversionAsset>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversionAssets(serial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get create scenes list by follow user
         * @param {number} limit 
         * @param {number} skip 
         * @param {string} [sort] 
         * @param {boolean} [desc] 
         * @param {number} [minRating] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreateList(limit: number, skip: number, sort?: string, desc?: boolean, minRating?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCreateListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreateList(limit, skip, sort, desc, minRating, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary creatorの詳細情報とそのユーザが公開しているscenesを取得する
         * @param {string} screenName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreatorScene(screenName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCreatorSceneResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreatorScene(screenName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Follow List
         * @param {string} [skip] 
         * @param {string} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFollowList(skip?: string, limit?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFollowListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFollowList(skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary マイリストの一覧を取得する
         * @param {string} [scGuid] シーンIDでの絞り込み
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMylist(scGuid?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMylistResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMylist(scGuid, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gallary notification list.
         * @param {number} serial bu_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(serial: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Notification>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifications(serial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This api get user scene detail by sc_guid.
         * @param {string} serial bu_serial
         * @param {string} scGuid sc_guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSceneBySceneId(serial: string, scGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scene>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSceneBySceneId(serial, scGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This api get user scene detail by sc_serial.
         * @param {string} scSerial sc_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSceneBySceneSerial(scSerial: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scene>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSceneBySceneSerial(scSerial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve scene list of the brand user even with unlisted scenes for logged in user
         * @param {string} serial bu_serial
         * @param {boolean} [noXml] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScenes(serial: string, noXml?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Scene>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScenes(serial, noXml, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザー固有のファイルを取得する
         * @param {string} userId userId
         * @param {string} type image, music, pdf, video
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadFile(userId: string, type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUploadFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadFile(userId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets users by usernames.
         * @param {Array<string>} usernames カンマ区切りのusername
         * @param {number} [onlyBusiness] integer 1 (TRUE) or 0 (FALSE)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersByUsernames(usernames: Array<string>, onlyBusiness?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UsersItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersByUsernames(usernames, onlyBusiness, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check Followed user
         * @param {number} creatorFollowSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isFollowed(creatorFollowSerial: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IsFollowedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isFollowed(creatorFollowSerial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete Follow List
         * @param {number} creatorFollowSerial creator_follow_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFollow(creatorFollowSerial: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFollow(creatorFollowSerial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary シーンのサムネイル画像を更新する
         * @param {number} scSerial sc_serial
         * @param {any} scThumbnailPhoto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSceneThumbnail(scSerial: number, scThumbnailPhoto: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scene>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSceneThumbnail(scSerial, scThumbnailPhoto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ユーザー固有のファイルをアップロードする
         * @param {string} userId userId
         * @param {string} type image, music, pdf, video
         * @param {string} [filename] 
         * @param {string} [blobname] Upload先(Azure)と紐づく文字列
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(userId: string, type: string, filename?: string, blobname?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(userId, type, filename, blobname, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary upload profile image
         * @param {any} [profile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadProfileImage(profile?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadProfileImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadProfileImage(profile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary upload user sns profile
         * @param {UploadProfileSnsRequet} [uploadProfileSnsRequet] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadProfileSns(uploadProfileSnsRequet?: UploadProfileSnsRequet, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadProfileSns(uploadProfileSnsRequet, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 通知を既読にする
         * @param {number} serial bu_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async watchNotifications(serial: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Notification>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.watchNotifications(serial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary add creator to follow-list
         * @param {number} creatorFollowSerial creator_follow_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFollow(creatorFollowSerial: number, options?: any): AxiosPromise<AddFollowResponse> {
            return localVarFp.addFollow(creatorFollowSerial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary シーンをコピーする
         * @param {string} sceneId sc_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyScene(sceneId: string, options?: any): AxiosPromise<Scene> {
            return localVarFp.copyScene(sceneId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register new styly asset conversion of the user
         * @param {string} serial bu_serial
         * @param {CreateConversionAssetRequest} createConversionAssetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConversionAsset(serial: string, createConversionAssetRequest: CreateConversionAssetRequest, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.createConversionAsset(serial, createConversionAssetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete specific styly asset of the user (logically)
         * @param {string} serial bu_serial
         * @param {string} guid asset guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAsset(serial: string, guid: string, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.deleteAsset(serial, guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve styly assets of the user
         * @param {string} serial bu_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssets(serial: string, options?: any): AxiosPromise<Array<Asset>> {
            return localVarFp.getAssets(serial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets how many times changing access level is allowed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableRestrictionOperations(options?: any): AxiosPromise<RestrictionOperationsResponse> {
            return localVarFp.getAvailableRestrictionOperations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve styly asset conversion list of the user
         * @param {string} serial bu_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversionAssets(serial: string, options?: any): AxiosPromise<Array<ConversionAsset>> {
            return localVarFp.getConversionAssets(serial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get create scenes list by follow user
         * @param {number} limit 
         * @param {number} skip 
         * @param {string} [sort] 
         * @param {boolean} [desc] 
         * @param {number} [minRating] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreateList(limit: number, skip: number, sort?: string, desc?: boolean, minRating?: number, options?: any): AxiosPromise<GetCreateListResponse> {
            return localVarFp.getCreateList(limit, skip, sort, desc, minRating, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary creatorの詳細情報とそのユーザが公開しているscenesを取得する
         * @param {string} screenName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreatorScene(screenName: string, options?: any): AxiosPromise<GetCreatorSceneResponse> {
            return localVarFp.getCreatorScene(screenName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Follow List
         * @param {string} [skip] 
         * @param {string} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowList(skip?: string, limit?: string, options?: any): AxiosPromise<GetFollowListResponse> {
            return localVarFp.getFollowList(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary マイリストの一覧を取得する
         * @param {string} [scGuid] シーンIDでの絞り込み
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMylist(scGuid?: string, skip?: number, limit?: number, options?: any): AxiosPromise<GetMylistResponse> {
            return localVarFp.getMylist(scGuid, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gallary notification list.
         * @param {number} serial bu_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(serial: number, options?: any): AxiosPromise<Array<Notification>> {
            return localVarFp.getNotifications(serial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This api get user scene detail by sc_guid.
         * @param {string} serial bu_serial
         * @param {string} scGuid sc_guid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSceneBySceneId(serial: string, scGuid: string, options?: any): AxiosPromise<Scene> {
            return localVarFp.getSceneBySceneId(serial, scGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This api get user scene detail by sc_serial.
         * @param {string} scSerial sc_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSceneBySceneSerial(scSerial: string, options?: any): AxiosPromise<Scene> {
            return localVarFp.getSceneBySceneSerial(scSerial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve scene list of the brand user even with unlisted scenes for logged in user
         * @param {string} serial bu_serial
         * @param {boolean} [noXml] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenes(serial: string, noXml?: boolean, options?: any): AxiosPromise<Array<Scene>> {
            return localVarFp.getScenes(serial, noXml, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー固有のファイルを取得する
         * @param {string} userId userId
         * @param {string} type image, music, pdf, video
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadFile(userId: string, type: string, options?: any): AxiosPromise<GetUploadFileResponse> {
            return localVarFp.getUploadFile(userId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets users by usernames.
         * @param {Array<string>} usernames カンマ区切りのusername
         * @param {number} [onlyBusiness] integer 1 (TRUE) or 0 (FALSE)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByUsernames(usernames: Array<string>, onlyBusiness?: number, options?: any): AxiosPromise<Array<UsersItem>> {
            return localVarFp.getUsersByUsernames(usernames, onlyBusiness, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check Followed user
         * @param {number} creatorFollowSerial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isFollowed(creatorFollowSerial: number, options?: any): AxiosPromise<IsFollowedResponse> {
            return localVarFp.isFollowed(creatorFollowSerial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profile(options?: any): AxiosPromise<LoginUser> {
            return localVarFp.profile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete Follow List
         * @param {number} creatorFollowSerial creator_follow_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFollow(creatorFollowSerial: number, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.removeFollow(creatorFollowSerial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary シーンのサムネイル画像を更新する
         * @param {number} scSerial sc_serial
         * @param {any} scThumbnailPhoto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSceneThumbnail(scSerial: number, scThumbnailPhoto: any, options?: any): AxiosPromise<Scene> {
            return localVarFp.updateSceneThumbnail(scSerial, scThumbnailPhoto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ユーザー固有のファイルをアップロードする
         * @param {string} userId userId
         * @param {string} type image, music, pdf, video
         * @param {string} [filename] 
         * @param {string} [blobname] Upload先(Azure)と紐づく文字列
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(userId: string, type: string, filename?: string, blobname?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.uploadFile(userId, type, filename, blobname, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary upload profile image
         * @param {any} [profile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfileImage(profile?: any, options?: any): AxiosPromise<UploadProfileImageResponse> {
            return localVarFp.uploadProfileImage(profile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary upload user sns profile
         * @param {UploadProfileSnsRequet} [uploadProfileSnsRequet] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfileSns(uploadProfileSnsRequet?: UploadProfileSnsRequet, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.uploadProfileSns(uploadProfileSnsRequet, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 通知を既読にする
         * @param {number} serial bu_serial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        watchNotifications(serial: number, options?: any): AxiosPromise<Array<Notification>> {
            return localVarFp.watchNotifications(serial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary add creator to follow-list
     * @param {number} creatorFollowSerial creator_follow_serial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addFollow(creatorFollowSerial: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).addFollow(creatorFollowSerial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary シーンをコピーする
     * @param {string} sceneId sc_serial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public copyScene(sceneId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).copyScene(sceneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register new styly asset conversion of the user
     * @param {string} serial bu_serial
     * @param {CreateConversionAssetRequest} createConversionAssetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createConversionAsset(serial: string, createConversionAssetRequest: CreateConversionAssetRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).createConversionAsset(serial, createConversionAssetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete specific styly asset of the user (logically)
     * @param {string} serial bu_serial
     * @param {string} guid asset guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteAsset(serial: string, guid: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteAsset(serial, guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve styly assets of the user
     * @param {string} serial bu_serial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAssets(serial: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getAssets(serial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets how many times changing access level is allowed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAvailableRestrictionOperations(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getAvailableRestrictionOperations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve styly asset conversion list of the user
     * @param {string} serial bu_serial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getConversionAssets(serial: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getConversionAssets(serial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get create scenes list by follow user
     * @param {number} limit 
     * @param {number} skip 
     * @param {string} [sort] 
     * @param {boolean} [desc] 
     * @param {number} [minRating] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getCreateList(limit: number, skip: number, sort?: string, desc?: boolean, minRating?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getCreateList(limit, skip, sort, desc, minRating, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary creatorの詳細情報とそのユーザが公開しているscenesを取得する
     * @param {string} screenName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getCreatorScene(screenName: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getCreatorScene(screenName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Follow List
     * @param {string} [skip] 
     * @param {string} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getFollowList(skip?: string, limit?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getFollowList(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary マイリストの一覧を取得する
     * @param {string} [scGuid] シーンIDでの絞り込み
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getMylist(scGuid?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getMylist(scGuid, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gallary notification list.
     * @param {number} serial bu_serial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getNotifications(serial: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getNotifications(serial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This api get user scene detail by sc_guid.
     * @param {string} serial bu_serial
     * @param {string} scGuid sc_guid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getSceneBySceneId(serial: string, scGuid: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getSceneBySceneId(serial, scGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This api get user scene detail by sc_serial.
     * @param {string} scSerial sc_serial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getSceneBySceneSerial(scSerial: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getSceneBySceneSerial(scSerial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve scene list of the brand user even with unlisted scenes for logged in user
     * @param {string} serial bu_serial
     * @param {boolean} [noXml] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getScenes(serial: string, noXml?: boolean, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getScenes(serial, noXml, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー固有のファイルを取得する
     * @param {string} userId userId
     * @param {string} type image, music, pdf, video
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUploadFile(userId: string, type: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUploadFile(userId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets users by usernames.
     * @param {Array<string>} usernames カンマ区切りのusername
     * @param {number} [onlyBusiness] integer 1 (TRUE) or 0 (FALSE)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsersByUsernames(usernames: Array<string>, onlyBusiness?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUsersByUsernames(usernames, onlyBusiness, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check Followed user
     * @param {number} creatorFollowSerial 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public isFollowed(creatorFollowSerial: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).isFollowed(creatorFollowSerial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get user profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public profile(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).profile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete Follow List
     * @param {number} creatorFollowSerial creator_follow_serial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public removeFollow(creatorFollowSerial: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).removeFollow(creatorFollowSerial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary シーンのサムネイル画像を更新する
     * @param {number} scSerial sc_serial
     * @param {any} scThumbnailPhoto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateSceneThumbnail(scSerial: number, scThumbnailPhoto: any, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateSceneThumbnail(scSerial, scThumbnailPhoto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ユーザー固有のファイルをアップロードする
     * @param {string} userId userId
     * @param {string} type image, music, pdf, video
     * @param {string} [filename] 
     * @param {string} [blobname] Upload先(Azure)と紐づく文字列
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public uploadFile(userId: string, type: string, filename?: string, blobname?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).uploadFile(userId, type, filename, blobname, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary upload profile image
     * @param {any} [profile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public uploadProfileImage(profile?: any, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).uploadProfileImage(profile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary upload user sns profile
     * @param {UploadProfileSnsRequet} [uploadProfileSnsRequet] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public uploadProfileSns(uploadProfileSnsRequet?: UploadProfileSnsRequet, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).uploadProfileSns(uploadProfileSnsRequet, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 通知を既読にする
     * @param {number} serial bu_serial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public watchNotifications(serial: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).watchNotifications(serial, options).then((request) => request(this.axios, this.basePath));
    }
}


