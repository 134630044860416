import { createSlice, createAsyncThunk, } from "@reduxjs/toolkit"
import { RootState, } from "app/store"
import { REDUX_ACTION_TYPE_PREFIX, } from "app/constants"
import { Session, } from "models/Session"
import { sessionApiClient, tunnelClient, } from "globalInstance"

const ACTION_TYPE_PREFIX = `${REDUX_ACTION_TYPE_PREFIX}/SessionPage`

export const initialize = createAsyncThunk<Session, string>(
  `${ACTION_TYPE_PREFIX}/initialize`,
  async (id) => {
    return await sessionApiClient.get(id)
  }
)

/**
 * Notifies session page load to the backend (Unity).
 */
export const notifyStart = createAsyncThunk(
  `${ACTION_TYPE_PREFIX}/notifyStart`,
  async (_, thunk) => {
    const state = thunk.getState() as RootState
    const { session, } = state.sessionPage
    if (!session) {
      throw new Error("No session")
    }
    await tunnelClient.notifySessionStart(session.displayId)
  }
)

/**
 * Notifies session page unload to the backend (Unity).
 */
export const notifyEnd = createAsyncThunk(
  `${ACTION_TYPE_PREFIX}/notifyEnd`,
  async (_, thunk) => {
    const state = thunk.getState() as RootState
    const { session, } = state.sessionPage
    if (!session) {
      throw new Error("No session")
    }
    await tunnelClient.notifySessionEnd(session.displayId)
  }
)

/**
 * Requests loading content to the backend (Unity).
 */
export const requestContentLoad = createAsyncThunk(
  `${ACTION_TYPE_PREFIX}/requestContentLoad`,
  async (_, thunk) => {
    const state = thunk.getState() as RootState
    const { session, } = state.sessionPage
    if (!session) {
      throw new Error("No session")
    }
    await tunnelClient.joinSession(session.displayId)
  }
)

const initialState = {
  loadingSession: false,
  loadingSessionError: false,
  session: null as Session | null,
  loadingContent: false,
  loadingContentError: false,
}

export const slice = createSlice({
  name: `${REDUX_ACTION_TYPE_PREFIX}/scenePage`,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initialize.pending, () => {
        return {
          ...initialState,
          loadingSession: true,
        }
      })
      .addCase(initialize.rejected, (state) => {
        state.loadingSession = false
        state.loadingSessionError = true
      })
      .addCase(initialize.fulfilled, (state, action) => {
        state.loadingSession = false
        state.session = action.payload
      })
      .addCase(requestContentLoad.pending, (state) => {
        state.loadingContent = true
        state.loadingContentError = false
      })
      .addCase(requestContentLoad.rejected, (state) => {
        state.loadingContent = false
        state.loadingContentError = true
      })
      .addCase(requestContentLoad.fulfilled, (state) => {
        state.loadingContent = false
      })
  },
})

export const selectState = (state: RootState) => {
  return {
    me: state.app.me,
    ...state.sessionPage,
  }
}

export default slice.reducer
