import { MultiplayerApi, Configuration, } from "../api_client_v1"
import { Session, } from "../../models/Session"

export class SessionApiClient {
  swaggerClient: MultiplayerApi

  constructor (basePath: string) {
    const config = new Configuration({
      basePath,
      baseOptions: {
        withCredentials: true,
      },
    })
    this.swaggerClient = new MultiplayerApi(config)
  }

  /**
   * Gets a session by a display ID.
   * @param displayId Display ID of a session.
   * @returns
   */
  async get (displayId: string) {
    const res = await this.swaggerClient.getSessionByDisplayId(displayId)
    const { data, } = res
    return {
      id: data.id,
      displayId: data.display_id,
      name: data.name,
      description: data.description,
      updatedAt: data.updated_at,
      createdAt: data.created_at,
    } as Session
  }
}
