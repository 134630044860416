import { configureStore, getDefaultMiddleware, } from "@reduxjs/toolkit"
import appReducer from "./App/redux"
import scenePageReducer from "pages/ScenePage/redux"
import sceneSearchReducer from "pages/SceneSearchPage/redux"
import staffPicksPageReducer from "pages/StaffPicksPage/redux"
import topPageReducer from "pages/TopPage/redux"
import userPageReducer from "pages/UserPage/redux"
import sessionPageReducer from "pages/SessionPage/redux"
import myPageReducer from "pages/MyPage/redux"
import searchBoxReducer from "reduxComponents/SearchBox/redux"
import logger from "redux-logger"
import { ENABLE_REDUX_LOGGER, } from "./env"

let middleware = getDefaultMiddleware()
if (ENABLE_REDUX_LOGGER) {
  middleware = middleware.concat(logger)
}

const store = configureStore({
  middleware: middleware,
  reducer: {
    app: appReducer,
    sceneSearch: sceneSearchReducer,
    scenePage: scenePageReducer,
    topPage: topPageReducer,
    staffPicksPage: staffPicksPageReducer,
    userPage: userPageReducer,
    myPage: myPageReducer,
    searchBox: searchBoxReducer,
    sessionPage: sessionPageReducer,
  },
})

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppStore = typeof store
