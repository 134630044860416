import React, { useState, useEffect, } from "react"
import { RouteComponentProps, } from "react-router-dom"
import style from "./style.module.scss"
import { login, signup, selectState, deleteBrowingHistory, } from "app/App/redux"
import { ConnectedProps, connect, useSelector, } from "react-redux"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import { validator, FieldKey, } from "pages/LoginPage/validator"
import history from "utils/history"
import { tunnelClient, } from "globalInstance"
import i18next from "i18next"
import queryString from "query-string"

const mapDispatch = { login, signup, deleteBrowingHistory, }
const connector = connect(null, mapDispatch)
type Props = ConnectedProps<typeof connector> & RouteComponentProps<{location: any}>

/**
 * Parses a query string.
 * @param searchQuery
 * @returns
 */
function parseQuery (searchQuery: string) {
  const parsedQuery = queryString.parse(searchQuery)
  // Sign up is alwasy disabled. This is because this App does not have account deletion functionality and it violates Apple policy.
  const signupFlag = false
  const session = parsedQuery.session
  let sessionDisplayId = null
  if (Array.isArray(session)) {
    sessionDisplayId = session[0]
  } else {
    sessionDisplayId = session
  }
  return {
    signup: signupFlag,
    sessionDisplayId,
  }
}

function LoginPage ({ location, deleteBrowingHistory, signup, login, }: Props) {
  const parsedQuery = parseQuery(location.search)
  const isSignup = parsedQuery.signup
  const sessionId = parsedQuery.sessionDisplayId
  const state = useSelector(selectState)
  const {
    me,
    authErrorMessage,
    service,
  } = state

  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errorUsername, setErrorUsername] = useState(null as (string | null))
  const [errorEmail, setErrorEmail] = useState(null as (string | null))
  const [errorPassword, setErrorPassword] = useState(null as (string | null))

  useEffect(() => {
    if (service === "STYLY_MOBILE") {
      deleteBrowingHistory()
    }
  }, [deleteBrowingHistory, isSignup, service])

  useEffect(() => {
    setErrorUsername(null)
    setErrorEmail(null)
    setErrorPassword(null)

    if (isSignup && username && !validator[FieldKey.USERNAME](username)) {
      setErrorUsername(i18next.t("loginPage.usernameErrorMessage"))
    }
    if (email && !validator[FieldKey.EMAIL](email)) {
      setErrorEmail(i18next.t("loginPage.emailErrorMessage"))
    }
    if (password && !validator[FieldKey.PASSWORD](password)) {
      setErrorPassword(i18next.t("loginPage.passwordErrorMessage"))
    }
  }, [username, email, password, isSignup])

  /**
   * SIGN UP押下時の処理
   */
  const onSignupClick = () => {
    if (username && email && password) {
      signup({ username, email, password, })
    }
  }

  /**
   * LOGIN押下時の処理
   */
  const onLoginClick = () => {
    if (email && password) {
      login({ email, password, })
    }
  }

  /**
   * Terms of Use押下時の処理
   */
  const onTermsOfUseClick = async () => {
    await tunnelClient.openExternalPage("https://styly.cc/styly-terms-of-use/")
  }

  /**
   * Privacy Policy押下時の処理
   */
  const onPrivacyPolicyClick = async () => {
    await tunnelClient.openExternalPage("https://styly.cc/styly-service-privacy-policy/")
  }

  const hasError = () => (errorUsername || errorEmail || errorPassword)

  useEffect(() => {
    if (me) {
      if (sessionId) {
        history.push(`/session/${sessionId}`)
      } else {
        history.push("/")
      }
    }
  }, [me, sessionId])

  return (
    <div className={style.frame}>
      <div className={style.wrapper}>
        <div className={style.header}>
          {(isSignup) ? i18next.t("common.signup") : i18next.t("common.login")}
        </div>
        {isSignup && (<TextField
          error={!!errorUsername}
          helperText={errorUsername}
          variant='outlined'
          fullWidth={true}
          label={i18next.t("common.username")}
          className={style.textfield}
          onChange={(e) => setUsername(e.target.value)}
        />)}
        <TextField
          error={!!errorEmail}
          helperText={errorEmail}
          variant='outlined'
          fullWidth={true}
          label={i18next.t("common.email")}
          className={style.textfield}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          error={!!errorPassword}
          helperText={errorPassword}
          variant='outlined'
          fullWidth={true}
          label={i18next.t("common.password")}
          type='password'
          className={style.textfield}
          onChange={(e) => setPassword(e.target.value)}
        />
        {authErrorMessage && (
          <div className={style.errorContainer}>{authErrorMessage}</div>
        )}
        {
          (isSignup) ? (
            <Button
              disabled={!(username && email && password && !hasError())}
              variant='contained'
              size='large'
              fullWidth={true}
              onClick={onSignupClick}
            >
              <span>{i18next.t("common.signup")}</span>
            </Button>
          ) : (
            <Button
              disabled={!(email && password && !hasError())}
              variant='contained'
              size='large'
              fullWidth={true}
              onClick={onLoginClick}
            >
              <span>{i18next.t("common.login")}</span>
            </Button>
          )
        }
        <div className={style.caption}>
          <span className={style.link} onClick={onTermsOfUseClick}>
            {i18next.t("common.termsOfUse")}
          </span>
          <span className={style.separater}>/</span>
          <span className={style.link} onClick={onPrivacyPolicyClick}>
            {i18next.t("common.privacyPolicy")}
          </span>
        </div>
      </div>
    </div>
  )
}

export default connector(LoginPage)
