import Tracker from "utils/tracker"
import { ContentfulClient, } from "utils/contentful"
import { TunnelClient, } from "utils/tunnel/client"
import {
  GOOGLE_ANALYTICS_CODE,
  CONTENTFUL_ENV,
  CONTENTFUL_SPACE,
  CONTENTFUL_ACCESS_TOKEN,
  API_BASE_PATH,
} from "./app/env"
import { SessionApiClient, } from "utils/api/SessionApiClient"

export const tracker = new Tracker(GOOGLE_ANALYTICS_CODE)
export const contentfulClient = new ContentfulClient(
  CONTENTFUL_SPACE,
  CONTENTFUL_ENV,
  CONTENTFUL_ACCESS_TOKEN
)
export const tunnelClient = new TunnelClient()
export const sessionApiClient = new SessionApiClient(API_BASE_PATH)
