import React, { useState, useEffect, } from "react"
import { notifyAppInitialized, initialize, slice, selectState, } from "./redux"
import { ConnectedProps, connect, useSelector, } from "react-redux"
import MarkerPopup from "features/MarkerPopup"
import TopPage from "pages/TopPage"
import SceneSearchPage from "pages/SceneSearchPage"
import ScenePage from "pages/ScenePage"
import StaffPicksPage from "pages/StaffPicksPage"
import UserPage from "pages/UserPage"
import OnboardingPage from "pages/OnboardingPage"
import LoginPage from "pages/LoginPage"
import MyPage from "pages/MyPage"
import SessionPage from "pages/SessionPage"
import Footer from "features/Footer"
import Header from "features/Header"
import { useLocation, Route, Switch, } from "react-router-dom"
import style from "./style.module.scss"
import history from "utils/history"
import { tunnelClient, } from "globalInstance"

const mapDispatch = { notifyAppInitialized, initialize, ...slice.actions, }
const connector = connect(null, mapDispatch)

type Props = ConnectedProps<typeof connector>

function App ({
  hideMarker,
  notifyAppInitialized,
  initialize,
  markOnboardingPageVisited,
}: Props) {
  const {
    markerSceneId,
    markerSceneTitle,
    markerSceneThumbnailUrl,
    initializing,
    initialized,
    initializeError,
    unityConnectionEstablished,
    unityConnectionEstablishError,
    firstLaunch,
    onboardingPageVisited,
    me,
  } = useSelector(selectState)
  const location = useLocation()
  const [showFooter, setShowFooter] = useState(true)

  const hideMarkerInternal = () => {
    hideMarker()
  }
  const onShareOnMarker = async () => {
    if (markerSceneId && markerSceneTitle) {
      await tunnelClient.shareScene(markerSceneId, markerSceneTitle)
    }
  }

  const onCameraClick = async () => {
    await tunnelClient.openCamera()
  }

  useEffect(() => {
    notifyAppInitialized()
  }, [notifyAppInitialized])

  useEffect(() => {
    // 初期化処理を実行
    if (unityConnectionEstablished && !initializeError && !initializing && !initialized) {
      initialize()
    }
  }, [unityConnectionEstablished, initializeError, initializing, initialized, initialize])
  useEffect(() => {
    if (unityConnectionEstablished && initialized) {
      if (firstLaunch && !onboardingPageVisited) {
        history.push("/onboarding")
        markOnboardingPageVisited()
      } else if (
        me &&
        me.features.mobileSkipTop &&
        // If the URL is a session page, do not let it go to mypage.
        !window.location.pathname.startsWith("/session/")
      ) {
        history.push("/mypage")
      }
    }
  }, [unityConnectionEstablished, initialized, firstLaunch, markOnboardingPageVisited, onboardingPageVisited, me])

  // Hide footer menu while inputing text.
  useEffect(() => {
    document.body.addEventListener("focus", (e: FocusEvent) => {
      const target = e.target as HTMLElement
      if (["INPUT", "TEXTAREA"].includes(target.tagName)) {
        setShowFooter(false)
      }
    }, true)
    document.body.addEventListener("blur", (e: FocusEvent) => {
      const target = e.target as HTMLElement
      if (["INPUT", "TEXTAREA"].includes(target.tagName)) {
        setShowFooter(true)
      }
    }, true)
  }, [])

  useEffect(() => {
    // フッター制御
    if (location.pathname.startsWith("/onboarding")) {
      setShowFooter(false)
    } else {
      setShowFooter(true)
    }
  }, [location])

  // Unity との通信経路が確保できなかった場合エラー表示
  if (unityConnectionEstablishError) {
    return (<div>Backend Communication Error</div>)
  }

  // 初期化処理に失敗した場合は要素を表示せず何も操作をさせない
  if (initializeError === true) {
    return (<div>Initialization Error</div>)
  }

  // 初期化処理が終了するまでは要素を出力しない
  if (!unityConnectionEstablished || !initialized) {
    return (<></>)
  }

  return (
    <div className={style.frame}>
      <Header />
      <Switch>
        <Route path="/search" component={SceneSearchPage} />
        <Route path="/scene/:id" component={ScenePage} />
        <Route path="/staff_picks" component={StaffPicksPage} />
        <Route path="/user/:id" component={UserPage} />
        <Route path="/onboarding" component={OnboardingPage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/mypage" component={MyPage} />
        <Route path="/session/:id" component={SessionPage} />
        <Route path="/" component={TopPage} />
      </Switch>
      { showFooter && (<Footer onCameraClick={onCameraClick} loggedIn={!!me} />) }
      <MarkerPopup
        open={!!markerSceneId}
        onClose={hideMarkerInternal}
        sceneId={markerSceneId || ""}
        onShare={onShareOnMarker}
        thumbnailUrl={markerSceneThumbnailUrl || ""}
      />
    </div>
  )
}

export default connector(App)
